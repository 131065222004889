import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf'; // For PDF download

const GSTPrintAndApprove = () => {
  const [type] = useState('bill');
  const [year, setYear] = useState('2023-2024');
  const [dateCondition, setDateCondition] = useState('equal');
  const [date, setDate] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [refNo, setRefNo] = useState('');
  const [approvalStatus, setApprovalStatus] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [bills, setBills] = useState([]);
  const [editBill, setEditBill] = useState(null); // State to handle editing

  useEffect(() => {
    fetchAllBills(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllBills = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'getBills');
      setBills(response.data);
      calculateTotalAmount(response.data);
    } catch (error) {
      console.error('Error fetching bills:', error);
    }
  };

  const calculateTotalAmount = (data) => {
    const total = data.reduce((acc, bill) => acc + parseFloat(bill.net_amount), 0);
    setTotalAmount(total);
  };

  const handleShowBills = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + 'filterBills', {
        year,
        dateCondition,
        date,
        customerName,
        refNo,
        approvalStatus,
      });
      setBills(response.data);
      calculateTotalAmount(response.data);
    } catch (error) {
      console.error('Error filtering bills:', error);
    }
  };

  const handleApproveBill = async (billNo) => {
    if (window.confirm('Are you sure you want to approve this bill?')) {
      try {
        await axios.put(`http://localhost:5000/api/approveBill/${billNo}`, { status: 'approved' });
        alert('Bill approved successfully!');
        fetchAllBills();
      } catch (error) {
        console.error('Error approving bill:', error);
      }
    }
  };

  const handleUnapproveBill = async (billNo) => {
    if (window.confirm('Are you sure you want to unapprove this bill?')) {
      try {
        await axios.put(`http://localhost:5000/api/approveBill/${billNo}`, { status: 'unapproved' });
        alert('Bill unapproved successfully!');
        fetchAllBills();
      } catch (error) {
        console.error('Error unapproving bill:', error);
      }
    }
  };

  const handleDeleteBill = async (billNo) => {
    if (window.confirm('Are you sure you want to delete this bill?')) {
      try {
        await axios.delete(`http://localhost:5000/api/deleteBill/${billNo}`);
        alert('Bill deleted successfully!');
        fetchAllBills();
      } catch (error) {
        console.error('Error deleting bill:', error);
      }
    }
  };

  const handlePrintBill = (bill) => {
    const doc = new jsPDF();
    doc.text(`Bill No: ${bill.bill_no}`, 10, 10);
    doc.text(`Date: ${formatDate(bill.date)}`, 10, 20);
    doc.text(`Ref No: ${bill.ref_no}`, 10, 30);
    doc.text(`Customer Name: ${bill.customer_name}`, 10, 40);
    doc.text(`Net Amount: ${bill.net_amount}`, 10, 50);
    doc.save(`Bill_${bill.bill_no}.pdf`);
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toLocaleDateString();
  };

  const handleEditBill = (bill) => {
    setEditBill({
      ...bill,
      date: bill.date ? new Date(bill.date).toISOString().split('T')[0] : '' // Convert to 'YYYY-MM-DD'
    }); // Set the bill to edit
  };

  const handleSaveEdit = async () => {
    if (!editBill) return;

    if (window.confirm('Are you sure you want to save these changes?')) {
      // Ensure all fields are filled; provide default values if necessary
      const updatedBill = {
        ...editBill,
        customerName: editBill.customer_name || '', // Provide default value if empty
        date: editBill.date ? new Date(editBill.date).toISOString() : '', // Convert to ISO string
        refNo: editBill.ref_no || '', // Provide default value if empty
        netAmount: editBill.net_amount || 0, // Provide default value if empty
        approvalStatus: editBill.approval_status || 'unapproved' // Provide default value if empty
      };

      try {
        await axios.put(`http://localhost:5000/api/updateBill/${editBill.bill_no}`, updatedBill);
        alert('Bill updated successfully!');
        setEditBill(null); // Clear edit mode
        fetchAllBills(); // Refresh list
      } catch (error) {
        console.error('Error updating bill:', error);
      }
    }
  };

  const handleChange = (e) => {
    setEditBill({ ...editBill, [e.target.name]: e.target.value }); // Update the state
  };

  return (
    <div className="gst-print-approve-page">
      <h1>GST Print and Approve</h1>
      <div className="filter-form">
        <label>Type:</label>
        <input type="text" value={type} readOnly />

        <label>Year:</label>
        <select value={year} onChange={(e) => setYear(e.target.value)}>
          <option value="2023-2024">2023-2024</option>
          <option value="2024-2025">2024-2025</option>
        </select>

        <label>Date Condition:</label>
        <select value={dateCondition} onChange={(e) => setDateCondition(e.target.value)}>
          <option value="equal">Equal</option>
          <option value="before">Before</option>
          <option value="after">After</option>
        </select>
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />

        <label>Customer Name:</label>
        <input
          type="text"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />

        <label>Ref No:</label>
        <input
          type="text"
          value={refNo}
          onChange={(e) => setRefNo(e.target.value)}
        />

        <label>Approval Status:</label>
        <select value={approvalStatus} onChange={(e) => setApprovalStatus(e.target.value)}>
          <option value="">All</option>
          <option value="approved">Approved</option>
          <option value="unapproved">Unapproved</option>
        </select>

        <button onClick={handleShowBills}>Show</button>
      </div>

      <h2>Total Amount: {totalAmount}</h2>

      <table>
        <thead>
          <tr>
            <th>Bill No</th>
            <th>Date</th>
            <th>Ref No</th>
            <th>Customer Name</th>
            <th>Net Amount</th>
            <th>Approval Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {bills.map((bill) => (
            <tr key={bill.bill_no}>
              <td>{bill.bill_no}</td>
              <td>{formatDate(bill.date)}</td>
              <td>{bill.ref_no}</td>
              <td>{bill.customer_name}</td>
              <td>{bill.net_amount}</td>
              <td>{bill.approval_status}</td>
              <td>
                {bill.approval_status === 'approved' ? (
                  <button onClick={() => handleUnapproveBill(bill.bill_no)}>Unapprove</button>
                ) : (
                  <button onClick={() => handleApproveBill(bill.bill_no)}>Approve</button>
                )}
                <button onClick={() => handleDeleteBill(bill.bill_no)}>Delete</button>
                <button onClick={() => handlePrintBill(bill)}>Print</button>
                <button onClick={() => handleEditBill(bill)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editBill && (
        <div className="edit-form">
          <h3>Edit Bill</h3>
          <label>Customer Name:</label>
          <input
            type="text"
            name="customer_name"
            value={editBill.customer_name}
            onChange={handleChange}
          />
          <label>Date:</label>
          <input
            type="date"
            name="date"
            value={editBill.date}
            onChange={handleChange}
          />
          <label>Ref No:</label>
          <input
            type="text"
            name="ref_no"
            value={editBill.ref_no}
            onChange={handleChange}
          />
          <label>Net Amount:</label>
          <input
            type="number"
            name="net_amount"
            value={editBill.net_amount}
            onChange={handleChange}
          />
          <button onClick={handleSaveEdit}>Save</button>
          <button onClick={() => setEditBill(null)}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default GSTPrintAndApprove;
