import React, { useState, useEffect } from 'react';
import './styles/Billing.css';
import axios from 'axios';

const Billing = () => {
  const [billNo, setBillNo] = useState('');
  const [year, setYear] = useState('2023-2024');
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [refNoOptions, setRefNoOptions] = useState([]);
  const [customerNameOptions, setCustomerNameOptions] = useState([]);
  const [refNoToCustomerName, setRefNoToCustomerName] = useState({});
  const [customerNameToRefNo, setCustomerNameToRefNo] = useState({});
  const [refNo, setRefNo] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [descriptionData, setDescriptionData] = useState([]);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [remarks, setRemarks] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    fetchRefNoAndCustomerNames();
    fetchLatestBillNo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRefNoAndCustomerNames = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'importJobData');
      const data = response.data;
      
      // Populate options and mappings
      const refNoOptions = data.map((job) => job.ref_no);
      const customerNameOptions = data.map((job) => job.importer_name);
      const refNoToCustomerName = {};
      const customerNameToRefNo = {};

      data.forEach(job => {
        refNoToCustomerName[job.ref_no] = job.importer_name;
        customerNameToRefNo[job.importer_name] = job.ref_no;
      });

      setRefNoOptions(refNoOptions);
      setCustomerNameOptions(customerNameOptions);
      setRefNoToCustomerName(refNoToCustomerName);
      setCustomerNameToRefNo(customerNameToRefNo);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const fetchLatestBillNo = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'getLatestBillNo');
      const latestBillNo = response.data.latestBillNo;
      setBillNo(formatBillNo(latestBillNo + 1)); // Format and set the new bill number
    } catch (error) {
      console.error('Error fetching latest bill number', error);
    }
  };

  const formatBillNo = (num) => {
    return num.toString().padStart(4, '0'); // Formats the number to be 4 digits with leading zeros
  };

  const handleAddDescription = () => {
    setShowPopup(true);
  };

  const handleSaveDescription = () => {
    const newDescription = {
      description,
      amount: parseFloat(amount),
      invoiceNo,
      remarks,
    };
    setDescriptionData([...descriptionData, newDescription]);
    setTotalAmount(totalAmount + newDescription.amount);
    setTax((totalAmount + newDescription.amount) * 0.1); // Assuming 10% tax rate
    setGrossAmount(totalAmount + newDescription.amount + tax);
    setDescription('');
    setAmount(0);
    setInvoiceNo('');
    setRemarks('');
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false); 
  };

  const handleCreateBill = async () => {
    try {
      await axios.post(process.env.REACT_APP_BACKEND_URL + 'createBill', {
        billNo,
        year,
        date,
        refNo,
        customerName,
        descriptionData,
        totalAmount,
        tax,
        grossAmount,
        advanceAmount,
        netAmount,
      });
      alert('Bill created successfully!');
      
      // Fetch the latest bill number and format it for the next bill
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'getLatestBillNo');
      const latestBillNo = response.data.latestBillNo;
      setBillNo(formatBillNo(latestBillNo + 1));

      // Clear fields after bill creation
      setRefNo('');
      setCustomerName('');
      setDescriptionData([]);
      setTotalAmount(0);
      setTax(0);
      setGrossAmount(0);
      setAdvanceAmount(0);
      setNetAmount(0);
    } catch (error) {
      console.error('Error creating bill', error);
    }
  };

  // Handle refNo input change
  const handleRefNoChange = (e) => {
    const value = e.target.value;
    setRefNo(value);
    setCustomerName(refNoToCustomerName[value] || '');
  };

  // Handle customerName input change
  const handleCustomerNameChange = (e) => {
    const value = e.target.value;
    setCustomerName(value);
    setRefNo(customerNameToRefNo[value] || '');
  };

  return (
    <div className="main-bill-billing-page">
      <h1 className="main-bill-title">Billing Page</h1>
      <div className="main-bill-form-container">
        <form className="main-bill-form">
          <div className="main-bill-form-group">
            <label htmlFor="billNo" className="main-bill-label">Bill No:</label>
            <input id="billNo" type="text" value={billNo} readOnly className="main-bill-input" />
          </div>
  
          <div className="main-bill-form-group">
            <label htmlFor="year" className="main-bill-label">Year:</label>
            <select id="year" value={year} onChange={(e) => setYear(e.target.value)} className="main-bill-select">
              <option value="2023-2024">2023-2024</option>
              <option value="2024-2025">2024-2025</option>
            </select>
          </div>
  
          <div className="main-bill-form-group">
            <label htmlFor="date" className="main-bill-label">Date:</label>
            <input id="date" type="date" value={date} onChange={(e) => setDate(e.target.value)} className="main-bill-input" />
          </div>
  
          <div className="main-bill-form-group">
            <label htmlFor="refNo" className="main-bill-label">Ref No:</label>
            <input
              id="refNo"
              type="text"
              value={refNo}
              onChange={handleRefNoChange}
              list="refNoOptions"
              className="main-bill-input"
            />
            <datalist id="refNoOptions">
              {refNoOptions.map((option) => (
                <option key={option} value={option} />
              ))}
            </datalist>
          </div>
  
          <div className="main-bill-form-group">
            <label htmlFor="customerName" className="main-bill-label">Customer Name:</label>
            <input
              id="customerName"
              type="text"
              value={customerName}
              onChange={handleCustomerNameChange}
              list="customerNameOptions"
              className="main-bill-input"
            />
            <datalist id="customerNameOptions">
              {customerNameOptions.map((option) => (
                <option key={option} value={option} />
              ))}
            </datalist>
          </div>
  
          <button type="button" onClick={handleAddDescription} className="main-bill-button">Add Description</button>
  
          {showPopup && (
            <div className="main-bill-description-popup">
              <h3 className="main-bill-popup-title">Add Description</h3>
              <div className="main-bill-form-group">
                <label htmlFor="description" className="main-bill-label">Description:</label>
                <input
                  id="description"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="main-bill-input"
                />
              </div>
  
              <div className="main-bill-form-group">
                <label htmlFor="amount" className="main-bill-label">Amount:</label>
                <input
                  id="amount"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="main-bill-input"
                />
              </div>
  
              <div className="main-bill-form-group">
                <label htmlFor="invoiceNo" className="main-bill-label">Invoice No:</label>
                <input
                  id="invoiceNo"
                  type="text"
                  value={invoiceNo}
                  onChange={(e) => setInvoiceNo(e.target.value)}
                  className="main-bill-input"
                />
              </div>
  
              <div className="main-bill-form-group">
                <label htmlFor="remarks" className="main-bill-label">Remarks:</label>
                <input
                  id="remarks"
                  type="text"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  className="main-bill-input"
                />
              </div>
  
              <button type="button" onClick={handleSaveDescription} className="main-bill-popup-button main-bill-popup-save-button">Save</button>
              <button type="button" onClick={handleClosePopup} className="main-bill-popup-button main-bill-popup-cancel-button">Cancel</button>
            </div>
          )}
  
          <h2 className="main-bill-description-title">Description Details</h2>
          {descriptionData.length > 0 && (
            <table className="main-bill-description-table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Invoice No</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {descriptionData.map((desc, index) => (
                  <tr key={index}>
                    <td>{desc.description}</td>
                    <td>{desc.amount}</td>
                    <td>{desc.invoiceNo}</td>
                    <td>{desc.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
  
          <div className="main-bill-summary">
            <div className="main-bill-form-group">
              <label htmlFor="totalAmount" className="main-bill-label">Total Amount:</label>
              <input id="totalAmount" type="number" value={totalAmount} readOnly className="main-bill-input" />
            </div>
  
            <div className="main-bill-form-group">
              <label htmlFor="tax" className="main-bill-label">Tax:</label>
              <input id="tax" type="number" value={tax} readOnly className="main-bill-input" />
            </div>
  
            <div className="main-bill-form-group">
              <label htmlFor="grossAmount" className="main-bill-label">Gross Amount:</label>
              <input id="grossAmount" type="number" value={grossAmount} readOnly className="main-bill-input" />
            </div>
  
            <div className="main-bill-form-group">
              <label htmlFor="advanceAmount" className="main-bill-label">Advance Amount:</label>
              <input
                id="advanceAmount"
                type="number"
                value={advanceAmount}
                onChange={(e) => setAdvanceAmount(e.target.value)}
                className="main-bill-input"
              />
            </div>
  
            <div className="main-bill-form-group">
              <label htmlFor="netAmount" className="main-bill-label">Net Amount:</label>
              <input
                id="netAmount"
                type="number"
                value={netAmount}
                onChange={(e) => setNetAmount(e.target.value)}
                className="main-bill-input"
              />
            </div>
  
            <button type="button" onClick={handleCreateBill} className="main-bill-button">Create Bill</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Billing;

