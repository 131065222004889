import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/FileUpload.css';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'file-upload';

const FileUpload = () => {
  const [refNo, setRefNo] = useState('');
  const [refNoOptions, setRefNoOptions] = useState([]);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchRefNoOptions();
    if (refNo) {
      fetchFiles(refNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNo]);

  const fetchRefNoOptions = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'import-job/ref-numbers');
      setRefNoOptions(response.data);
    } catch (err) {
      console.error('Error fetching ref numbers:', err);
      setError('Error fetching ref numbers');
    }
  };

  const fetchFiles = async (refNo) => {
    try {
      const response = await axios.get(`${BASE_URL}/${refNo}`);
      setFiles(response.data);
    } catch (err) {
      console.error('Error fetching files:', err);
      setError('Error fetching files');
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !refNo) {
      setError('Reference number and file are required');
      setTimeout(() => {
        setError('');
    }, 5000);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('ref_no', refNo);

    try {
      await axios.post(BASE_URL, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      setSuccessMessage('File uploaded successfully');
      setTimeout(() => {
        setSuccessMessage('');
    }, 5000);
      setFile(null);
      fetchFiles(refNo);
    } catch (err) {
      console.error('Error uploading file:', err);
      setError('Error uploading file');
      setTimeout(() => {
        setError('');
    }, 5000);
    }
  };

  const handleDownload = (filePath) => {
    const fileName = filePath.split('/').pop().split('\\').pop();
    window.open(`${BASE_URL}/download/${encodeURIComponent(fileName)}`, '_blank');
  };

  const handleInputChange = (e) => {
    setRefNo(e.target.value);
    if (e.target.value) {
      fetchFiles(e.target.value); // Optional: Fetch files as user types
    }
  };

  return (
<div className="file-upload-container">
  <h1 className="file-upload-title">File Upload</h1>
  
  <div className="file-upload-form">
    <label className="file-upload-label">Reference Number</label>
    <input 
      type="text" 
      className="file-upload-input" 
      value={refNo} 
      onChange={handleInputChange} 
      list="refNoOptions" 
      placeholder="Type or select reference number"
    />
    <datalist id="refNoOptions">
      {refNoOptions.map((option) => (
        <option key={option.ref_no} value={option.ref_no} />
      ))}
    </datalist>
    
    <label className="file-upload-label">Choose File</label>
    <input 
      type="file" 
      className="file-upload-file-input" 
      onChange={handleFileChange} 
    />
    
    <button 
      className="file-upload-button" 
      onClick={handleUpload}
    >
      Upload
    </button>
    
    {error && <p className="file-upload-error">{error}</p>}
    {successMessage && <p className="file-upload-success">{successMessage}</p>}
  </div>

  {files.length > 0 && (
    <div className="file-upload-list">
      <h2 className="file-upload-list-title">Uploaded Files</h2>
      <table className="file-upload-table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file) => (
            <tr key={file.id}>
              <td>{file.file_name}</td>
              <td>
                <button 
                  className="file-upload-download-button" 
                  onClick={() => handleDownload(file.file_path)}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )}
</div>
  );
};

export default FileUpload;
