// ProtectedRoute.jsx
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ component: Component }) => {
  const { user, isAuthReady } = useContext(AuthContext);

  if (!isAuthReady) {
    // You can add a loader here if you want while auth state is being determined
    return null; // or a loading spinner component
  }

  return user ? <Component /> : <Navigate to="/" />;
};

export default ProtectedRoute;
