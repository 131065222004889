import React, { useState } from 'react';
import './styles/BillOutstandingReport.css';

const BillOutstandingReport = () => {
  const [dateOption, setDateOption] = useState('Equal');
  const [date, setDate] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [year, setYear] = useState('2021-2022');
  const [reportType, setReportType] = useState('Summary');

  const handleView = () => {
    // Fetch and display data based on selected filters
    console.log('View report with filters:', { dateOption, date, customerName, year, reportType });
  };

  const handleDownload = () => {
    // Generate and download the report in Excel format
    console.log('Download report with filters:', { dateOption, date, customerName, year, reportType });
  };

  return (
    <div className="bill-outstanding-report">
      <h1>Bill Outstanding Report</h1>
      <div className="bill-outstanding-form">
        <div className="bill-outstanding-form-group">
          <label>Date</label>
          <div className="bill-outstanding-field-group">
            <select value={dateOption} onChange={(e) => setDateOption(e.target.value)}>
              <option value="Before">Before</option>
              <option value="Equal">Equal</option>
              <option value="After">After</option>
            </select>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        </div>
        <div className="bill-outstanding-form-group">
          <label>Customer Name</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>
        <div className="bill-outstanding-form-group">
          <label>Year</label>
          <select value={year} onChange={(e) => setYear(e.target.value)}>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            <option value="2023-2024">2023-2024</option>
          </select>
        </div>
        <div className="bill-outstanding-form-group">
          <label>Report Type</label>
          <select value={reportType} onChange={(e) => setReportType(e.target.value)}>
            <option value="Summary">Summary</option>
            <option value="Detailed">Detailed</option>
            {/* Add more report types if needed */}
          </select>
        </div>
        <div className="bill-outstanding-button-group">
          <button onClick={handleView}>View</button>
          <button onClick={handleDownload}>Download</button>
        </div>
      </div>
    </div>
  );
};

export default BillOutstandingReport;
