import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './styles/TaxMaster.css';
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'tax-master';

const TaxMaster = () => {
  const [serviceTax, setServiceTax] = useState('');
  const [transportTax, setTransportTax] = useState('');
  const [taxes, setTaxes] = useState([]); 
  const [inactiveTaxes, setInactiveTaxes] = useState([]);
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [viewMode, setViewMode] = useState('active');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const { user } = useContext(AuthContext);
  const username = user.username;

  useEffect(() => {
    fetchTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  const fetchTaxes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${viewMode === 'active' ? '' : '/inactive'}`);
      viewMode === 'active' ? setTaxes(response.data) : setInactiveTaxes(response.data);
    } catch (err) {
      console.error('Error fetching taxes:', err);
      setError('Error fetching taxes');
    }
  };

  const handleSave = async () => {
    if (!serviceTax || !transportTax) {
      setError('Service Tax and Transport Tax are required');
      return;
    }

    try {
      if (editId) {
        await axios.put(`${BASE_URL}/${editId}`, { serviceTax, transportTax, updatedBy: username });
        setEditId(null);
        setSuccessMessage('Updated successfully');
      } else {
        await axios.post(BASE_URL, { serviceTax, transportTax, createdBy: username });
        setSuccessMessage('Saved successfully');
      }
      fetchTaxes();
      resetForm();
    } catch (err) {
      console.error('Error saving tax:', err);
      setError('Error saving tax');
    }
  };

  const handleEdit = (tax) => {
    setServiceTax(tax.service_tax);
    setTransportTax(tax.transport_tax);
    setEditId(tax.id);
  };

  const handleDelete = async () => {
    const isPermanent = deleteType === 'permanent';
    try {
      await axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${deleteId}`);
      fetchTaxes();
      setShowDeleteDialog(false);
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting tax:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting tax`);
    }
  };

  const handleBulkDelete = async () => {
    const isPermanent = viewMode === 'inactive';
    try {
      await Promise.all(selectedRecords.map(id => axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${id}`)));
      fetchTaxes();
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting taxes:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting taxes`);
    }
  };

  const handleSearch = () => {
    fetchTaxes();
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilterBy('');
    resetForm();
    fetchTaxes();
    setSuccessMessage('Reset successfully');
  };

  const resetForm = () => {
    setServiceTax('');
    setTransportTax('');
    setEditId(null);
    setError('');
  };

  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((recordId) => recordId !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    const allIds = (viewMode === 'active' ? taxes : inactiveTaxes).map(tax => tax.id);
    setSelectedRecords(selectedRecords.length === allIds.length ? [] : allIds);
  };

  const filteredTaxes = (viewMode === 'active' ? taxes : inactiveTaxes).filter((tax) => {
    if (!searchTerm) return true;
    switch (filterBy) {
      case 'service_tax':
        return tax.service_tax.includes(searchTerm);
      case 'transport_tax':
        return tax.transport_tax.includes(searchTerm);
      case 'created_by':
        return tax.created_by.includes(searchTerm);
      case 'updated_by':
        return tax.updated_by.includes(searchTerm);
      case 'month':
        return new Date(tax.created_at).getMonth() + 1 === parseInt(searchTerm);
      case 'year':
        return new Date(tax.created_at).getFullYear() === parseInt(searchTerm);
      default:
        return Object.values(tax).some(value => String(value).includes(searchTerm));
    }
  });

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className="tax-master">
    <h1 className="tax-master-title">Tax Master</h1>
    <div className="tax-master-form">
      <label className="tax-master-label">Service Tax</label>
      <input
        type="text"
        className="tax-master-input"
        value={serviceTax}
        onChange={(e) => setServiceTax(e.target.value)}
      />
      <label className="tax-master-label">Transport Tax</label>
      <input
        type="text"
        className="tax-master-input"
        value={transportTax}
        onChange={(e) => setTransportTax(e.target.value)}
      />
      <div className="tax-master-button-group">
        <button className="tax-master-button save" onClick={handleSave}>
          {editId ? 'Update' : 'Save'}
        </button>
        <button className="tax-master-button reset" onClick={handleReset}>
          Reset
        </button>
      </div>
      {error && <p className="tax-master-message error">{error}</p>}
      {successMessage && <p className="tax-master-message success">{successMessage}</p>}
    </div>
    <div className="tax-master-search-filters">
      <input
        type="text"
        className="tax-master-search-input"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <select
        className="tax-master-filter-dropdown"
        value={filterBy}
        onChange={(e) => setFilterBy(e.target.value)}
      >
        <option value="">Filter by</option>
        <option value="service_tax">Service Tax</option>
        <option value="transport_tax">Transport Tax</option>
        <option value="created_by">Created By</option>
        <option value="updated_by">Updated By</option>
        <option value="month">Month</option>
        <option value="year">Year</option>
      </select>
      <button className="tax-master-button-search" onClick={handleSearch}>
        Search
      </button>
      <button className="tax-master-button reset" onClick={handleReset}>
        Reset
      </button>
    </div>
    <div className="tax-master-view-buttons">
      <button
        className={`tax-master-view-button ${viewMode === 'active' ? 'active' : ''}`}
        onClick={() => setViewMode('active')}
        style={{ backgroundColor: viewMode === 'active' ? 'green' : 'initial' }}
      >
        View Active
      </button>
      <button
        className={`tax-master-view-button ${viewMode === 'inactive' ? 'inactive' : ''}`}
        onClick={() => setViewMode('inactive')}
        style={{ backgroundColor: viewMode === 'inactive' ? 'red' : 'initial' }}
      >
        View Deleted
      </button>
    </div>
    {filteredTaxes.length > 0 ? (
      <>
        <table className="tax-master-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  className="tax-master-select-all-checkbox"
                  checked={selectedRecords.length === filteredTaxes.length}
                  onChange={handleSelectAll}
                />
              </th>
              <th>ID</th>
              <th>Service Tax</th>
              <th>Transport Tax</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Created By</th>
              <th>Updated By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTaxes.map((tax) => (
              <tr key={tax.id}>
                <td>
                  <input
                    type="checkbox"
                    className="tax-master-record-checkbox"
                    checked={selectedRecords.includes(tax.id)}
                    onChange={() => handleCheckboxChange(tax.id)}
                  />
                </td>
                <td>{tax.id}</td>
                <td>{tax.service_tax}</td>
                <td>{tax.transport_tax}</td>
                <td>{formatTimestamp(tax.created_at)}</td>
                <td>{formatTimestamp(tax.updated_at)}</td>
                <td>{tax.created_by}</td>
                <td>{tax.updated_by}</td>
                <td>
                  {viewMode === 'active' ? (
                    <>
                      <button className="tax-master-button edit" onClick={() => handleEdit(tax)}>
                        Edit
                      </button>
                      <button
                        className="tax-master-button delete"
                        onClick={() => {
                          setShowDeleteDialog(true);
                          setDeleteType('soft');
                          setDeleteId(tax.id);
                        }}
                      >
                        Delete
                      </button>
                    </>
                  ) : (
                    <button
                      className="tax-master-button delete-permanent"
                      onClick={() => {
                        setShowDeleteDialog(true);
                        setDeleteType('permanent');
                        setDeleteId(tax.id);
                      }}
                    >
                      Delete Permanently
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {selectedRecords.length > 0 && (
          <button className="tax-master-button bulk-delete" onClick={handleBulkDelete}>
            Delete Selected
          </button>
        )}
      </>
    ) : (
      <p className="tax-master-no-records-message">No taxes found</p>
    )}
    {showDeleteDialog && (
      <div className="tax-master-dialog">
        <div className="tax-master-dialog-content">
          <p className="tax-master-dialog-text">
            Are you sure you want to {deleteType === 'permanent' ? 'permanently ' : ''}delete this tax?
          </p>
          <button className="tax-master-button confirm" onClick={handleDelete}>
            Yes
          </button>
          <button className="tax-master-button cancel" onClick={() => setShowDeleteDialog(false)}>
            No
          </button>
        </div>
      </div>
    )}
  </div>
  
  );
};

export default TaxMaster;
