import React, { useState } from 'react';
import './styles/DeletedInformation.css';

const DeletedInformation = () => {
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [infoType, setInfoType] = useState('');
  const [partyName, setPartyName] = useState('');
  const [ref, setRef] = useState('');
  const [year, setYear] = useState('');

  const handleSearch = () => {
    // Handle search action
    console.log('Searching with:', { dateFilter, date, infoType, partyName, ref, year });
  };

  const handleDownload = () => {
    // Handle Excel download
    console.log('Downloading Excel with:', { dateFilter, date, infoType, partyName, ref, year });
  };

  return (
    <div className="deleted-information">
      <h1>Deleted Information</h1>
      <div className="deleted-information-form">
        <div className="deleted-information-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="deleted-information-form-group">
          <label>Type of Information</label>
          <select
            value={infoType}
            onChange={(e) => setInfoType(e.target.value)}
          >
            <option value="">Select Type</option>
            <option value="type1">Type 1</option>
            <option value="type2">Type 2</option>
            {/* Add more type options as needed */}
          </select>
        </div>
        <div className="deleted-information-form-group">
          <label>Party Name</label>
          <input
            type="text"
            value={partyName}
            onChange={(e) => setPartyName(e.target.value)}
          />
        </div>
        <div className="deleted-information-form-group">
          <label>Ref#</label>
          <input
            type="text"
            value={ref}
            onChange={(e) => setRef(e.target.value)}
          />
        </div>
        <div className="deleted-information-form-group">
          <label>Year</label>
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">Select Year</option>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            {/* Add more year options as needed */}
          </select>
        </div>
        <div className="deleted-information-button-group">
          <button onClick={handleSearch}>Search</button>
          <button onClick={handleDownload}>Download Excel</button>
        </div>
      </div>
    </div>
  );
};

export default DeletedInformation;
