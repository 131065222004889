import React, { useState, useEffect, useCallback } from 'react';
import './styles/VoucherPrintApprove.css';
import { jsPDF } from 'jspdf';
import axios from 'axios';

const VoucherPrintApprove = () => {
  const [vouchers, setVouchers] = useState([]);
  const [filters, setFilters] = useState({
    type: 'Voucher',
    year: '',
    date: '',
    dateField: 'equal',
    customerName: '',
    refNo: '',
    expenseType: '',
    approvalStatus: '',
  });

  const [editingVoucher, setEditingVoucher] = useState(null); 
  const [editForm, setEditForm] = useState({}); 
  const [customerNameOptions, setCustomerNameOptions] = useState([]);
  const [refNoOptions, setRefNoOptions] = useState([]);
  const [expenseTypeOptions, setExpenseTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const fetchVouchers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // Convert the date to 'YYYY-MM-DD' format
      const formattedFilters = { ...filters };
      if (formattedFilters.date) {
        formattedFilters.date = new Date(formattedFilters.date).toISOString().split('T')[0];
      }
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'voucher-entry', { params: formattedFilters });
      setVouchers(response.data);
    } catch (error) {
      setError('Error fetching vouchers.');
      console.error('Error fetching vouchers:', error);
    } finally {
      setLoading(false);
    }
  }, [filters]);
  
  useEffect(() => {
    fetchVouchers();
  }, [fetchVouchers]);

  const fetchCustomerNameOptions = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'customer-names');
      setCustomerNameOptions(response.data);
    } catch (error) {
      console.error('Error fetching customer names:', error);
      setCustomerNameOptions([]);
    }
  }, []);

  const fetchRefNoOptions = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'ref-no-options');
      setRefNoOptions(response.data);
    } catch (error) {
      console.error('Error fetching refNo options:', error);
      setRefNoOptions([]);
    }
  }, []);

  const fetchExpenseTypeOptions = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'expense-types');
      setExpenseTypeOptions(response.data);
    } catch (error) {
      console.error('Error fetching expense types:', error);
      setExpenseTypeOptions([]);
    }
  }, []);

  useEffect(() => {
    fetchCustomerNameOptions();
    fetchRefNoOptions();
    fetchExpenseTypeOptions();
  }, [fetchCustomerNameOptions, fetchRefNoOptions, fetchExpenseTypeOptions]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleEdit = (voucher) => {
    setEditingVoucher(voucher.voucher_number);
    setEditForm(voucher);
  };

  const handleEditFormChange = (e) => {
    setEditForm({ ...editForm, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`http://localhost:5000/api/${editingVoucher}`, editForm);
      fetchVouchers();
      setEditingVoucher(null);
    } catch (error) {
      console.error('Error updating voucher:', error);
    }
  };

  const handleCancel = () => {
    setEditingVoucher(null);
  };

  const handleDelete = async (voucher_number) => {
    if (window.confirm('Are you sure you want to delete this voucher?')) {
      try {
        await axios.delete(`http://localhost:5000/api/voucher-entry/delete/${voucher_number}`);
        fetchVouchers();
      } catch (error) {
        console.error('Error deleting voucher:', error);
        alert(error.response.data.error);
      }
    }
  };

  const handleApproval = async (voucher_number, approvalStatus) => {
    try {
      await axios.put(process.env.REACT_APP_BACKEND_URL + 'voucher-entry/approval', { voucher_number, approvalStatus });
      fetchVouchers();
    } catch (error) {
      console.error('Error updating approval status:', error);
    }
  };

  const handlePrint = (voucher) => {
    if (!voucher) {
      console.error('Voucher data is missing');
      return;
    }
  
    const doc = new jsPDF();
  
    doc.text(`Voucher Number: ${voucher.voucher_number || ''}`, 10, 10);
    doc.text(`Date: ${formatTimestamp(voucher.date) || ''}`, 10, 20);
    doc.text(`Reference Number: ${voucher.ref_no || ''}`, 10, 30);
    doc.text(`Party: ${voucher.party || ''}`, 10, 40);
    doc.text(`Voucher Head: ${voucher.voucher_head || ''}`, 10, 50);
    doc.text(`Total Amount: ${voucher.total_amt || ''}`, 10, 60);
    doc.text(`Prepared By: ${voucher.prepared_by || ''}`, 10, 70);  
  
    doc.save(`Voucher_${voucher.voucher_number}.pdf`);  
  };

  return (
    <div className="v-management-container">
      <h1 className="v-management-header">Voucher Approve and Print</h1>

      {/* Filters Section */}
      <div className="v-filters-section">
        <input
          type="text"
          name="type"
          className="v-filter-input"
          value={filters.type}
          readOnly
        />
        <select name="year" className="v-filter-select" value={filters.year} onChange={handleFilterChange}>
          <option value="">Select Year</option>
          <option value="2021-2022">2021-2022</option>
          <option value="2022-2023">2022-2023</option>
          <option value="2023-2024">2023-2024</option>
        </select>

        <select name="dateField" className="v-filter-select" value={filters.dateField} onChange={handleFilterChange}>
          <option value="equal">Equal</option>
          <option value="before">Before</option>
          <option value="after">After</option>
        </select>

        <input
          type="date"
          name="date"
          className="v-filter-input"
          value={filters.date ? new Date(filters.date).toISOString().split('T')[0] : ''}
          onChange={handleFilterChange}
          placeholder="Date"
        />

        <select name="customerName" className="v-filter-select" value={filters.customerName} onChange={handleFilterChange}>
          <option value="">Select Customer Name</option>
          {customerNameOptions.map((name, index) => (
            <option key={index} value={name}>{name}</option>
          ))}
        </select>

        <select name="refNo" className="v-filter-select" value={filters.refNo} onChange={handleFilterChange}>
          <option value="">Select Ref No</option>
          {refNoOptions.map((refNo, index) => (
            <option key={index} value={refNo}>{refNo}</option>
          ))}
        </select>

        <select name="expenseType" className="v-filter-select" value={filters.expenseType} onChange={handleFilterChange}>
          <option value="">Select Expense Type</option>
          {expenseTypeOptions.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>

        <select name="approvalStatus" className="v-filter-select" value={filters.approvalStatus} onChange={handleFilterChange}>
          <option value="">All</option>
          <option value="Approved">Approved</option>
          <option value="Unapproved">Unapproved</option>
        </select>

        <button className="v-filter-button" onClick={fetchVouchers} disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </button>
      </div>

      {error && <div className="v-error-message">{error}</div>}

      <table className="v-voucher-table">
        <thead className="v-voucher-table-head">
          <tr>
            <th>Voucher Number</th>
            <th>Date</th>
            <th>Ref No</th>
            <th>Party</th>
            <th>Voucher Head</th>
            <th>Expense Type</th>
            <th>Total Amount</th>
            <th>Prepared By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="v-voucher-table-body">
          {vouchers.map((voucher) => (
            <tr key={voucher.voucher_number}>
              {editingVoucher === voucher.voucher_number ? (
                <>
                  <td>{voucher.voucher_number}</td>
                  <td>
                    <input
                      type="date"
                      name="date"
                      className="v-edit-input"
                      value={formatTimestamp(editForm.date)}
                      onChange={handleEditFormChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="ref_no"
                      className="v-edit-input"
                      value={editForm.ref_no}
                      onChange={handleEditFormChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="party"
                      className="v-edit-input"
                      value={editForm.party}
                      onChange={handleEditFormChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="voucher_head"
                      className="v-edit-input"
                      value={editForm.voucher_head}
                      onChange={handleEditFormChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="expense_type"
                      className="v-edit-input"
                      value={editForm.expense_type}
                      onChange={handleEditFormChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="total_amt"
                      className="v-edit-input"
                      value={editForm.total_amt}
                      onChange={handleEditFormChange}
                    />
                  </td>
                  <td>{voucher.prepared_by}</td>
                  <td className="v-actions-buttons">
                    <button className="v-save-button" onClick={handleSave}>Save</button>
                    <button className="v-cancel-button" onClick={handleCancel}>Cancel</button>
                  </td>
                </>
              ) : (
                <>
                  <td>{voucher.voucher_number}</td>
                  <td>{formatTimestamp(voucher.date)}</td>
                  <td>{voucher.ref_no}</td>
                  <td>{voucher.party}</td>
                  <td>{voucher.voucher_head}</td>
                  <td>{voucher.expense_type}</td>
                  <td>{voucher.total_amt}</td>
                  <td>{voucher.prepared_by}</td>
                  <td className="v-actions-buttons">
                    <button className="v-edit-button" onClick={() => handleEdit(voucher)}>Edit</button>
                    <button className="v-delete-button" onClick={() => handleDelete(voucher.voucher_number)}>Delete</button>
                    <button className="v-approve-button" onClick={() => handleApproval(voucher.voucher_number, voucher.approval_status === 'Approved' ? 'Unapproved' : 'Approved')}>
                      {voucher.approval_status === 'Approved' ? 'Unapprove' : 'Approve'}
                    </button>
                    <button className="v-print-button" onClick={() => handlePrint(voucher)}>Print</button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VoucherPrintApprove;
