import React, { useState } from 'react';
import './styles/BillPaymentDetailsReport.css';

const BillPaymentDetailsReport = () => {
  const [yearRange, setYearRange] = useState('2022-2023');
  const [dateOption, setDateOption] = useState('Equal');
  const [date, setDate] = useState('');
  const [party, setParty] = useState('');
  const [reportType, setReportType] = useState('Summary');
  const [dateFilter, setDateFilter] = useState('billDate');

  const handleView = () => {
    // Fetch and display data based on selected filters
    console.log('View report with filters:', { yearRange, dateOption, date, party, reportType, dateFilter });
  };

  const handleDownload = () => {
    // Generate and download the report in Excel format
    console.log('Download report with filters:', { yearRange, dateOption, date, party, reportType, dateFilter });
  };

  return (
    <div className="bill-payment-details-report">
      <h1>Bill Payment Details Report</h1>
      <div className="bill-payment-form">
        <div className="bill-payment-form-group">
          <label>Year Range</label>
          <select value={yearRange} onChange={(e) => setYearRange(e.target.value)}>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            <option value="2023-2024">2023-2024</option>
          </select>
        </div>
        <div className="bill-payment-form-group">
          <label>Date</label>
          <div className="bill-payment-field-group">
            <select value={dateOption} onChange={(e) => setDateOption(e.target.value)}>
              <option value="Before">Before</option>
              <option value="Equal">Equal</option>
              <option value="After">After</option>
            </select>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        </div>
        <div className="bill-payment-form-group">
          <label>Party</label>
          <input
            type="text"
            value={party}
            onChange={(e) => setParty(e.target.value)}
          />
        </div>
        <div className="bill-payment-form-group">
          <label>Report Type</label>
          <select value={reportType} onChange={(e) => setReportType(e.target.value)}>
            <option value="Summary">Summary</option>
            <option value="Detailed">Detailed</option>
          </select>
        </div>
        <div className="bill-payment-form-group radio-group">
          <label>Filter by:</label>
          <div className="radio-options">
            <label>
              <input
                type="radio"
                value="billDate"
                checked={dateFilter === 'billDate'}
                onChange={() => setDateFilter('billDate')}
              />
              Bill Date
            </label>
            <label>
              <input
                type="radio"
                value="paidDate"
                checked={dateFilter === 'paidDate'}
                onChange={() => setDateFilter('paidDate')}
              />
              Paid Date
            </label>
          </div>
        </div>
        <div className="bill-payment-button-group">
          <button onClick={handleView}>View</button>
          <button onClick={handleDownload}>Download</button>
        </div>
      </div>
    </div>
  );
};

export default BillPaymentDetailsReport;
