import React, { useState } from 'react';
import './styles/JobDetailsReport.css';

const JobDetailsReport = () => {
  const [jobType, setJobType] = useState('uncleared');
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [customer, setCustomer] = useState('');
  const [year, setYear] = useState('');
  const [yearTo, setYearTo] = useState('');

  const handleSubmit = () => {
    // Handle submit action
    console.log('Submit:', { jobType, dateFilter, date, customer, year, yearTo });
  };

  const handleSendDSR = () => {
    // Handle send DSR action
    console.log('Send DSR:', { jobType, dateFilter, date, customer, year, yearTo });
  };

  return (
    <div className="job-details">
      <h1>Job Details</h1>
      <div className="job-details-form">
        <div className="job-details-form-group">
          <label>Job Type</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                value="uncleared"
                checked={jobType === 'uncleared'}
                onChange={() => setJobType('uncleared')}
              />
              Uncleared Jobs
            </label>
            <label>
              <input
                type="radio"
                value="cleared"
                checked={jobType === 'cleared'}
                onChange={() => setJobType('cleared')}
              />
              Cleared Jobs
            </label>
            <label>
              <input
                type="radio"
                value="both"
                checked={jobType === 'both'}
                onChange={() => setJobType('both')}
              />
              Both
            </label>
          </div>
        </div>
        <div className="job-details-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="job-details-form-group">
          <label>Customer</label>
          <input
            type="text"
            value={customer}
            onChange={(e) => setCustomer(e.target.value)}
          />
        </div>
        <div className="job-details-form-group">
          <label>Year</label>
          <input
            type="text"
            placeholder="From"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
          <input
            type="text"
            placeholder="To"
            value={yearTo}
            onChange={(e) => setYearTo(e.target.value)}
          />
        </div>
        <div className="job-details-button-group">
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={handleSendDSR}>Send DSR</button>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsReport;
