import React, { useState } from 'react';
import './styles/JobUpdation.css';
import axios from 'axios';

const ImportJobUpdation = () => {
    const [refNo, setRefNo] = useState('');
    const [records, setRecords] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [updateFields, setUpdateFields] = useState({});
    const [updationType, setUpdationType] = useState(''); 
    const [invoiceDetails, setInvoiceDetails] = useState({
        be_no: '',
        be_date: '',
        value: '',
        duty_amount: '',
        tr6_challan_no: '',
        tr6_challan_date: '',
        goods_rcvd_on: ''
    });
    const [clearanceDetails, setClearanceDetails] = useState({
        cleared_on: '',
        transporter_name: '',
        v_no: '',
        h_days: '',
        amount: '',
        advance: '',
        balance: '',
        incidental_description: '',
        incidental_amount: '',
        ot_details: false,
        charges_description: '',
        agent_name: '',
        rcpt: '',
        rcpt_date: '',
        charges_amount: '',
        halting_days: ''
    });
    const [hasInvoiceDetails, setHasInvoiceDetails] = useState(false);
    const [hasClearanceDetails, setHasClearanceDetails] = useState(false);
    const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
    const [isFreightPopupOpen, setIsFreightPopupOpen] = useState(false);
    const [isTransportPopupOpen, setIsTransportPopupOpen] = useState(false);
    const [isIncidentalPopupOpen, setIsIncidentalPopupOpen] = useState(false);
    const [isChargesPopupOpen, setIsChargesPopupOpen] = useState(false);

    const handleSearch = async () => {
        try {
            const response = await axios.get(`http://localhost:5000/api/import-job/search`, { params: { ref_no: refNo } });
            setRecords(response.data);
        } catch (error) {
            console.error('Error fetching records:', error);
        }
    };

    const formatDateForInput = (dateString) => {
      if (!dateString) return '';
  
      const date = new Date(dateString);
  
      if (isNaN(date.getTime())) return '';
  
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
  
      return `${year}-${month}-${day}`;
  };

    const handleRecordSelect = async (record) => {
        setSelectedRecord(record);
        setUpdateFields(record);

        try {
            const invoiceResponse = await axios.get(`http://localhost:5000/api/import-job/get-invoice-details`, { params: { ref_no: record.ref_no } });
            if (invoiceResponse.data) {
                setInvoiceDetails(invoiceResponse.data);
                setHasInvoiceDetails(true);
            } else {
                setInvoiceDetails({
                    be_no: '',
                    be_date: '',
                    value: '',
                    duty_amount: '',
                    tr6_challan_no: '',
                    tr6_challan_date: '',
                    goods_rcvd_on: ''
                });
                setHasInvoiceDetails(false);
            }

            const clearanceResponse = await axios.get(`http://localhost:5000/api/import-job/get-clearance-details`, { params: { ref_no: record.ref_no } });
            if (clearanceResponse.data) {
                setClearanceDetails(clearanceResponse.data);
                setHasClearanceDetails(true);
            } else {
                setClearanceDetails({
                    cleared_on: '',
                    transporter_name: '',
                    v_no: '',
                    h_days: '',
                    amount: '',
                    advance: '',
                    balance: '',
                    incidental_description: '',
                    incidental_amount: '',
                    ot_details: false,
                    charges_description: '',
                    agent_name: '',
                    rcpt: '',
                    rcpt_date: '',
                    charges_amount: '',
                    halting_days: ''
                });
                setHasClearanceDetails(false);
            }
        } catch (error) {
            console.error('Error fetching invoice details:', error);
        }

    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setUpdateFields(prev => ({ ...prev, [name]: value }));
    };

    const handleInvoiceChange = (e) => {
        const { name, value } = e.target;
        setInvoiceDetails(prev => ({ ...prev, [name]: value }));
    };

    const handleClearanceChange = (e) => {
        const { name, value } = e.target;
        setClearanceDetails(prev => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setClearanceDetails(prev => ({ ...prev, [name]: checked }));
    };

    const handleUpdate = async () => {
        try {
            await axios.put(`http://localhost:5000/api/import-job/update`, updateFields);

            if (updationType === 'Document Details') {
                if (hasInvoiceDetails) {
                    await axios.put(`http://localhost:5000/api/import-job/update-invoice-details`, {
                        ref_no: refNo,
                        ...invoiceDetails
                    });
                } else {
                    await axios.post(`http://localhost:5000/api/import-job/save-invoice-details`, {
                        ref_no: refNo,
                        ...invoiceDetails
                    });
                }
            } else if (updationType === 'Clearance') {
                if (hasClearanceDetails) {
                    await axios.put(`http://localhost:5000/api/import-job/update-clearance-details`, {
                        ref_no: refNo,
                        ...clearanceDetails
                    });
                } else {
                    await axios.post(`http://localhost:5000/api/import-job/save-clearance-details`, {
                        ref_no: refNo,
                        ...clearanceDetails
                    });
                }
            }

            alert('Record updated successfully');
        } catch (error) {
            console.error('Error updating record:', error);
        }
    };

    return (
        <div className="export-job-registration">
            <div className="job-form-title">
                <h1>Job Updation</h1>
            </div>
            <div className="upd-form-group">
                <label>Ref No:</label>
                <input type="text" value={refNo} onChange={(e) => setRefNo(e.target.value)} />
                <button className="import-job-ref-button" onClick={handleSearch}>Search</button>
            </div>
    
            {records.length > 0 && (
                <div>
                    <h2>Select a Record to Update</h2>
                    <ul className="upd-record-list">
                        {records.map(record => (
                            <li key={record.id} onClick={() => handleRecordSelect(record)}>
                                {record.ref_no} - {record.importer_name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
    
            {selectedRecord && (
                <div className="upd-form-container">
                    <h2>Update Record</h2>
                    <form>
                        <div className="upd-form-group">
                            <label>Updation:</label>
                            <select className="import-job-select-type" onChange={(e) => setUpdationType(e.target.value)}>
                                <option value="">Select</option>
                                <option value="Document Details">Document Details</option>
                                <option value="Clearance">Clearance</option>
                            </select>
                        </div>
    
                        <div className="upd-form-row">
                            <div className="upd-form-field">
                                <label>Service Type:</label>
                                <input type="text" name="service_type" value={updateFields.service_type || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Import/Export No:</label>
                                <input type="text" name="import_no" value={updateFields.import_no || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Date:</label>
                                <input type="date" name="date" value={formatDateForInput(updateFields.date) || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Job#:</label>
                                <input type="text" name="job_id" value={updateFields.job_id || ''} onChange={handleFieldChange} />
                            </div>
                        </div>
    
                        <div className="upd-form-row">
                            <div className="upd-form-field">
                                <label>Job Date:</label>
                                <input type="date" name="job_date" value={formatDateForInput(updateFields.job_date) || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Trans Mode:</label>
                                <input type="text" name="trans_mode" value={updateFields.trans_mode || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Branch:</label>
                                <input type="text" name="branch" value={updateFields.branch || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>BE/SB Type:</label>
                                <input type="text" name="be_sb_type" value={updateFields.be_type || ''} onChange={handleFieldChange} />
                            </div>
                        </div>
    
                        <div className="upd-form-row">
                            <div className="upd-form-field">
                                <label>Importer/Exporter:</label>
                                <input type="text" name="importer_name" value={updateFields.importer_name || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Consignor/Consignee:</label>
                                <input type="text" name="consignor_name" value={updateFields.consignor_name || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Place of Shipment:</label>
                                <input type="text" name="country_of_shipment" value={updateFields.country_of_shipment || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Place of Delivery:</label>
                                <input type="text" name="port_of_shipment" value={updateFields.port_of_shipment || ''} onChange={handleFieldChange} />
                            </div>
                        </div>
    
                        <div className="upd-form-row">
                            <div className="upd-form-field">
                                <label>Net Wt:</label>
                                <input type="text" name="net_wt" value={updateFields.net_wt || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Net Wt Unit:</label>
                                <input type="text" name="net_wt_unit" value={updateFields.net_wt_unit || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Gross Wt:</label>
                                <input type="text" name="gross_wt" value={updateFields.gross_wt || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Gross Wt Unit:</label>
                                <input type="text" name="gross_wt_unit" value={updateFields.gross_wt_unit || ''} onChange={handleFieldChange} />
                            </div>
                            </div>

                            <div className="upd-form-row">
                            <div className="upd-form-field">
                                <label>No. of Pkgs:</label>
                                <input type="text" name="num_of_pkgs" value={updateFields.num_of_pkgs || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>No. of Pkgs Unit:</label>
                                <input type="text" name="num_of_pkgs_unit" value={updateFields.num_of_pkgs_unit || ''} onChange={handleFieldChange} />
                            </div>
                            <div className="upd-form-field">
                                <label>Goods Rcvd on:</label>
                                <input type="date" name="goods_rcvd_on" value={formatDateForInput(invoiceDetails.goods_rcvd_on)} onChange={handleInvoiceChange} />
                            </div>
                        </div>
    
                        {updationType === 'Document Details' && (
                            <div>
                                <h3>Document Details</h3>
                                <div className="upd-form-row">
                                    <div className="upd-form-field">
                                        <label>B.E No:</label>
                                        <input type="text" name="be_no" value={invoiceDetails.be_no} onChange={handleInvoiceChange} />
                                    </div>
                                    <div className="upd-form-field">
                                        <label>Date:</label>
                                        <input type="date" name="be_date" value={formatDateForInput(invoiceDetails.be_date)} onChange={handleInvoiceChange} />
                                    </div>
                                    <div className="upd-form-field">
                                        <label>Value:</label>
                                        <input type="text" name="value" value={invoiceDetails.value} onChange={handleInvoiceChange} />
                                    </div>
                                    <div className="upd-form-field">
                                        <label>Duty Amount:</label>
                                        <input type="text" name="duty_amount" value={invoiceDetails.duty_amount} onChange={handleInvoiceChange} />
                                    </div>
                                </div>
    
                                <div className="upd-form-row">
                                    <div className="upd-form-field">
                                        <label>TR6 Challan No:</label>
                                        <input type="text" name="tr6_challan_no" value={invoiceDetails.tr6_challan_no} onChange={handleInvoiceChange} />
                                    </div>
                                    <div className="upd-form-field">
                                        <label>TR6 Challan Date:</label>
                                        <input type="date" name="tr6_challan_date" value={formatDateForInput(invoiceDetails.tr6_challan_date)} onChange={handleInvoiceChange} />
                                    </div>
                                    </div>
                                    <div className="upd-form-row">
                                    <div className="upd-form-field">
                                        <label>Invoice Details:</label>
                                        <button type="button" className="import-job-details-button" onClick={() => setIsInvoicePopupOpen(true)}>Invoice Details</button>
                                    </div>
    
                                {isInvoicePopupOpen && (
                                    <div className="upd-popup">
                                        <div className="upd-popup-inner">
                                            <div className="upd-popup-header">
                                                <h4>Invoice Details</h4>

                                            </div>
                                            <div className="upd-popup-content">
                                                <div className="form-row">
                                                    <div className="form-group">
                                                        <label>Terms:</label>
                                                        <input type="text" name="terms" value={updateFields.terms} onChange={handleFieldChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Currency:</label>
                                                        <input type="text" name="currency" value={updateFields.currency} onChange={handleFieldChange} />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group">
                                                        <label>Invoice:</label>
                                                        <input type="text" name="invoice" value={updateFields.invoice} onChange={handleFieldChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Invoice Date:</label>
                                                        <input type="date" name="invoice_date" value={formatDateForInput(updateFields.invoice_date)} onChange={handleFieldChange} />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group">
                                                        <label>PO:</label>
                                                        <input type="text" name="po" value={updateFields.po} onChange={handleFieldChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
    PO Date:</label>
            <input type="date" name="po_date" value={formatDateForInput(updateFields.po_date)} onChange={handleFieldChange} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Invoice Description:</label>
            <textarea name="invoice_description" value={updateFields.invoice_description} onChange={handleFieldChange}></textarea>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Quantity (Qty):</label>
            <input type="number" step="0.01" name="qty" value={updateFields.qty} onChange={handleFieldChange} />
          </div>
          <div className="form-group">
            <label>Unit:</label>
            <input type="text" name="unit" value={updateFields.unit} onChange={handleFieldChange} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Unit Price:</label>
            <input type="number" step="0.01" name="unit_price" value={updateFields.unit_price} onChange={handleFieldChange} />
          </div>
          <div className="form-group">
            <label>Unit Value:</label>
            <input type="text" name="unit_value" value={updateFields.unit_value} onChange={handleFieldChange} />
          </div>
        </div>

        <div className="form-actions">
        <button type="button" onClick={() => setIsInvoicePopupOpen(false)}>Save</button>
          <button type="button" onClick={() => setIsInvoicePopupOpen(false)}>Close</button>
        </div>
      </div>
    </div>
  </div>
)}

<div className="upd-form-field">
  <label>Freight Details: </label>
  <button type="button" className="import-job-details-button" onClick={() => setIsFreightPopupOpen(true)}>Freight Details</button>
</div>
</div>

{isFreightPopupOpen && (
  <div className="popup">
    <div className="popup-inner">
      <div className="form-header">
        <h4>Freight Details</h4>

        <div className="form-row">
          <div className="form-group">
            <label>Freight:</label>
            <input type="number" step="0.01" name="freight" value={updateFields.freight} onChange={handleFieldChange} />
          </div>
          <div className="form-group">
            <label>Insurance:</label>
            <input type="number" step="0.01" name="insurance" value={updateFields.insurance} onChange={handleFieldChange} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Other Charges:</label>
            <input type="number" step="0.01" name="other_charges" value={updateFields.other_charges} onChange={handleFieldChange} />
          </div>
          <div className="form-group">
            <label>FOB Value:</label>
            <input type="number" step="0.01" name="fob_value" value={updateFields.fob_value} onChange={handleFieldChange} />
          </div>
        </div>

        <div className="form-actions">
        <button type="button" onClick={() => setIsFreightPopupOpen(false)}>Save</button>
          <button type="button" onClick={() => setIsFreightPopupOpen(false)}>Close</button>
        </div>
      </div>
    </div>
  </div>
)}

                            </div>
                        )}

                        {updationType === 'Clearance' && (
                            <div>
                                <h3>Clearance Details</h3>
                                <div className="upd-form-row">
                                <div className="upd-form-field">
                                    <label>Cleared On:</label>
                                    <input type="date" name="cleared_on" value={formatDateForInput(clearanceDetails.cleared_on)} onChange={handleClearanceChange} />
                                </div>
                                
                                <div className="upd-form-field">
                                    <label>Transport Details: </label>
                                    <button type="button" className="import-job-details-button" onClick={() => setIsTransportPopupOpen(true)}>Transport Details</button>
                                </div>
                                {isTransportPopupOpen && (<div className="popup">
                        <div className="popup-inner">
                                <div className="form-header">
                                    <h4>Transport Details</h4>
                                    <div className="form-row">
                                    <div className="form-group">
                                        <label>Transporter Name:</label>
                                        <input type="text" name="transporter_name" value={clearanceDetails.transporter_name} onChange={handleClearanceChange} />
                                    </div>
                                    <div className="form-group">
                                        <label>V No:</label>
                                        <input type="text" name="v_no" value={clearanceDetails.v_no} onChange={handleClearanceChange} />
                                    </div>
                                    </div>
                                    <div className="form-row">
                                    <div className="form-group">
                                        <label>H Days:</label>
                                        <input type="text" name="h_days" value={clearanceDetails.h_days} onChange={handleClearanceChange} />
                                    </div>
                                    <div className="form-group">
                                        <label>Amount:</label>
                                        <input type="text" name="amount" value={clearanceDetails.amount} onChange={handleClearanceChange} />
                                    </div>
                                    </div>
                                    <div className="form-row">
                                    <div className="form-group">
                                        <label>Advance:</label>
                                        <input type="text" name="advance" value={clearanceDetails.advance} onChange={handleClearanceChange} />
                                    </div>
                                    <div className="form-group">
                                        <label>Balance:</label>
                                        <input type="text" name="balance" value={clearanceDetails.balance} onChange={handleClearanceChange} />
                                    </div>
                                    </div>
                                    <div className="form-actions">
                                    <button type="button" onClick={() => setIsTransportPopupOpen(false)}>
          Save
        </button>
        <button type="button" onClick={() => setIsTransportPopupOpen(false)}>
          Close
        </button>
      </div>
                                </div> 
                                </div>
                                </div>)}
                                <div className="upd-form-field">
  <label>Incidental Expenses: </label>
  <button type="button" className="import-job-details-button" onClick={() => setIsIncidentalPopupOpen(true)}>Incidental Expenses</button>
</div>
</div>
{isIncidentalPopupOpen && (
  <div className="popup">
    <div className="popup-inner">
      <div className="form-header">
        <h4>Incidental Expenses</h4>
        <div className="form-row">
          <div className="form-group">
            <label>Description:</label>
            <input
              type="text"
              name="incidental_description"
              value={clearanceDetails.incidental_description}
              onChange={handleClearanceChange}
            />
          </div>
          <div className="form-group">
            <label>Amount:</label>
            <input
              type="text"
              name="incidental_amount"
              value={clearanceDetails.incidental_amount}
              onChange={handleClearanceChange}
            />
          </div>
        </div>
        <div className="form-actions">
        <button type="button" onClick={() => setIsIncidentalPopupOpen(false)}>
            Save
          </button>
          <button type="button" onClick={() => setIsIncidentalPopupOpen(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}
<div className="upd-form-row">
                                <div className="upd-form-field">
                                    <label>OT Details:</label>
                                    <input type="checkbox" name="ot_details" checked={clearanceDetails.ot_details} onChange={handleCheckboxChange} />
                                </div>
                                <div className="upd-form-field">
  <label>Charges: </label>
  <button type="button" className="import-job-details-button" onClick={() => setIsChargesPopupOpen(true)}>Charges</button>
</div>
{isChargesPopupOpen && (
  <div className="popup">
    <div className="popup-inner">
      <div className="form-header">
        <h4>Charges</h4>
        <div className="form-row">
          <div className="form-group">
            <label>Description:</label>
            <input
              type="text"
              name="charges_description"
              value={clearanceDetails.charges_description}
              onChange={handleClearanceChange}
            />
          </div>
          <div className="form-group">
            <label>Agent Name:</label>
            <input
              type="text"
              name="agent_name"
              value={clearanceDetails.agent_name}
              onChange={handleClearanceChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Rcpt:</label>
            <input
              type="text"
              name="rcpt"
              value={clearanceDetails.rcpt}
              onChange={handleClearanceChange}
            />
          </div>
          <div className="form-group">
            <label>Rcpt Date:</label>
            <input
              type="date"
              name="rcpt_date"
              value={formatDateForInput(clearanceDetails.rcpt_date)}
              onChange={handleClearanceChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Amount:</label>
            <input
              type="text"
              name="charges_amount"
              value={clearanceDetails.charges_amount}
              onChange={handleClearanceChange}
            />
          </div>
        </div>
        <div className="form-actions">
        <button type="button" onClick={() => setIsChargesPopupOpen(false)}>
            Save
          </button>
          <button type="button" onClick={() => setIsChargesPopupOpen(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}
                                <div className="upd-form-field">
                                    <label>Halting Days:</label>
                                    <input type="text" name="halting_days" value={clearanceDetails.halting_days} onChange={handleClearanceChange} />
                                </div>
                            </div>
                            </div>
                        )}

                        <div>
                            <button type="button" className="import-job-save-button" onClick={handleUpdate}>Update</button>
                            <button type="button" className="import-job-reset-button" onClick={() => setSelectedRecord(null)}>Cancel</button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default ImportJobUpdation;
