import React, { useState } from 'react';
import './styles/VoucherDetailsReport.css';

const VoucherDetailsReport = () => {
  const [year, setYear] = useState('');
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [voucherType, setVoucherType] = useState('');
  const [anotherOption, setAnotherOption] = useState('');
  const [billType, setBillType] = useState('');
  const [refNumber, setRefNumber] = useState('');

  const handleSearch = () => {
    // Fetch and view voucher details data
    console.log('Searching data with:', { year, dateFilter, date, voucherType, anotherOption, billType, refNumber });
  };

  const handleClear = () => {
    // Reset all filters
    setYear('');
    setDateFilter('equal');
    setDate('');
    setVoucherType('');
    setAnotherOption('');
    setBillType('');
    setRefNumber('');
  };

  const handleDownload = () => {
    // Handle Excel download
    console.log('Downloading Excel with:', { year, dateFilter, date, voucherType, anotherOption, billType, refNumber });
  };

  return (
    <div className="voucher-details-report">
      <h1>Voucher Details Report</h1>
      <div className="voucher-details-report-form">
        <div className="voucher-details-report-form-group">
          <label>Year</label>
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">Select Year</option>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            <option value="2023-2024">2023-2024</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="voucher-details-report-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="voucher-details-report-form-group">
          <label>Voucher Type</label>
          <select
            value={voucherType}
            onChange={(e) => setVoucherType(e.target.value)}
          >
            <option value="">Select Voucher Type</option>
            <option value="Voucher">Voucher</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="voucher-details-report-form-group">
          <label></label>
          <select
            value={anotherOption}
            onChange={(e) => setAnotherOption(e.target.value)}
          >
            <option value="">Select Option</option>
            <option value="Option1">Option1</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="voucher-details-report-form-group">
          <label>Bill Type</label>
          <select
            value={billType}
            onChange={(e) => setBillType(e.target.value)}
          >
            <option value="">Select Bill Type</option>
            <option value="Bill1">Bill1</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="voucher-details-report-form-group">
          <label>Ref#</label>
          <input
            type="text"
            value={refNumber}
            onChange={(e) => setRefNumber(e.target.value)}
          />
        </div>
        <div className="voucher-details-report-button-group">
          <button onClick={handleSearch}>Search</button>
          <button onClick={handleClear}>Clear</button>
          <button onClick={handleDownload}>Download Excel</button>
        </div>
      </div>
    </div>
  );
};

export default VoucherDetailsReport;
