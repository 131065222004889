import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './styles/ClientMaster.css';
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'client-master';

const ClientMaster = () => {
  const [clientName, setClientName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [gstIn, setGstIn] = useState('');
  const [lopNo, setLopNo] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [accountEmail, setAccountEmail] = useState('');
  const [additionalAddress, setAdditionalAddress] = useState('');
  const [clients, setClients] = useState([]);
  const [inactiveClients, setInactiveClients] = useState([]);
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [viewMode, setViewMode] = useState('active');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const { user } = useContext(AuthContext);
  const username = user.username;

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${viewMode === 'active' ? '' : '/inactive'}`);
      viewMode === 'active' ? setClients(response.data) : setInactiveClients(response.data);
    } catch (err) {
      console.error('Error fetching clients:', err);
      setError('Error fetching clients');
    }
  };

  const handleSave = async () => {
    if (!clientName || !contactPerson) {
      setError('Client Name and Contact Person are required');
      return;
    }

    const clientData = {
      client_name: clientName,
      contact_person: contactPerson,
      gst_in: gstIn,
      lop_no: lopNo,
      address: address,
      city: city,
      state: state,
      country: country,
      zip_code: zipCode,
      phone_number: phoneNumber,
      mobile_number: mobileNumber,
      email_address: emailAddress,
      account_email: accountEmail,
      additional_address: additionalAddress,
      created_by: username,
      updated_by: username,
    };

    try {
      if (editId) {
        await axios.put(`${BASE_URL}/${editId}`, clientData);
        setEditId(null);
        setSuccessMessage('Updated successfully');
      } else {
        await axios.post(BASE_URL, clientData);
        setSuccessMessage('Saved successfully');
      }
      fetchClients();
      resetForm();
    } catch (err) {
      console.error('Error saving client:', err);
      setError('Error saving client');
    }
  };

  const handleEdit = (client) => {
    setClientName(client.client_name);
    setContactPerson(client.contact_person);
    setGstIn(client.gst_in);
    setLopNo(client.lop_no);
    setAddress(client.address);
    setCity(client.city);
    setState(client.state);
    setCountry(client.country);
    setZipCode(client.zip_code);
    setPhoneNumber(client.phone_number);
    setMobileNumber(client.mobile_number);
    setEmailAddress(client.email_address);
    setAccountEmail(client.account_email);
    setAdditionalAddress(client.additional_address);
    setEditId(client.id);
  };

  const handleDelete = async () => {
    const isPermanent = deleteType === 'permanent';
    try {
      await axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${deleteId}`);
      fetchClients();
      setShowDeleteDialog(false);
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting client:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting client`);
    }
  };

  const handleBulkDelete = async () => {
    const isPermanent = viewMode === 'inactive';
    try {
      await Promise.all(selectedRecords.map(id => axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${id}`)));
      fetchClients();
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting clients:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting clients`);
    }
  };

  const handleSearch = () => {
    fetchClients();
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilterBy('');
    resetForm();
    fetchClients();
    setSuccessMessage('Reset successfully');
  };

  const resetForm = () => {
    setClientName('');
    setContactPerson('');
    setGstIn('');
    setLopNo('');
    setAddress('');
    setCity('');
    setState('');
    setCountry('');
    setZipCode('');
    setPhoneNumber('');
    setMobileNumber('');
    setEmailAddress('');
    setAccountEmail('');
    setAdditionalAddress('');
    setEditId(null);
    setError('');
  };

  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((recordId) => recordId !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    const allIds = (viewMode === 'active' ? clients : inactiveClients).map(client => client.id);
    setSelectedRecords(selectedRecords.length === allIds.length ? [] : allIds);
  };

  const filteredClients = (viewMode === 'active' ? clients : inactiveClients).filter((client) => {
    if (!searchTerm) return true;
    switch (filterBy) {
      case 'client_name':
        return client.client_name.includes(searchTerm);
      case 'contact_person':
        return client.contact_person.includes(searchTerm);
      case 'gst_in':
        return client.gst_in.includes(searchTerm);
      case 'city':
        return client.city.includes(searchTerm);
      case 'state':
        return client.state.includes(searchTerm);
      case 'country':
        return client.country.includes(searchTerm);
      default:
        return Object.values(client).some(value => String(value).includes(searchTerm));
    }
  });

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
<div className="client-master">
  <h1 className="client-master-title">Client Master</h1>
  <div className="client-master-form">
    <label className="client-master-label">Client Name</label>
    <input
      type="text"
      className="client-master-input"
      value={clientName}
      onChange={(e) => setClientName(e.target.value)}
    />
    <label className="client-master-label">Contact Person</label>
    <input
      type="text"
      className="client-master-input"
      value={contactPerson}
      onChange={(e) => setContactPerson(e.target.value)}
    />
    <label className="client-master-label">GST IN</label>
    <input
      type="text"
      className="client-master-input"
      value={gstIn}
      onChange={(e) => setGstIn(e.target.value)}
    />
    <label className="client-master-label">LOP No</label>
    <input
      type="text"
      className="client-master-input"
      value={lopNo}
      onChange={(e) => setLopNo(e.target.value)}
    />
    <label className="client-master-label">Address</label>
    <input
      type="text"
      className="client-master-input"
      value={address}
      onChange={(e) => setAddress(e.target.value)}
    />
    <label className="client-master-label">City</label>
    <input
      type="text"
      className="client-master-input"
      value={city}
      onChange={(e) => setCity(e.target.value)}
    />
    <label className="client-master-label">State</label>
    <input
      type="text"
      className="client-master-input"
      value={state}
      onChange={(e) => setState(e.target.value)}
    />
    <label className="client-master-label">Country</label>
    <input
      type="text"
      className="client-master-input"
      value={country}
      onChange={(e) => setCountry(e.target.value)}
    />
    <label className="client-master-label">Zip Code</label>
    <input
      type="text"
      className="client-master-input"
      value={zipCode}
      onChange={(e) => setZipCode(e.target.value)}
    />
    <label className="client-master-label">Phone Number</label>
    <input
      type="text"
      className="client-master-input"
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(e.target.value)}
    />
    <label className="client-master-label">Mobile Number</label>
    <input
      type="text"
      className="client-master-input"
      value={mobileNumber}
      onChange={(e) => setMobileNumber(e.target.value)}
    />
    <label className="client-master-label">Email Address</label>
    <input
      type="email"
      className="client-master-input"
      value={emailAddress}
      onChange={(e) => setEmailAddress(e.target.value)}
    />
    <label className="client-master-label">Account Email</label>
    <input
      type="email"
      className="client-master-input"
      value={accountEmail}
      onChange={(e) => setAccountEmail(e.target.value)}
    />
    <label className="client-master-label">Additional Address</label>
    <input
      type="text"
      className="client-master-input"
      value={additionalAddress}
      onChange={(e) => setAdditionalAddress(e.target.value)}
    />
    <div className="client-master-button-group">
      <button className="client-master-button save" onClick={handleSave}>
        {editId ? 'Update' : 'Save'}
      </button>
      <button className="client-master-button reset" onClick={handleReset}>
        Reset
      </button>
    </div>
    {error && <p className="client-master-message error">{error}</p>}
    {successMessage && <p className="client-master-message success">{successMessage}</p>}
  </div>
  <div className="client-master-search-filters">
    <input
      type="text"
      className="client-master-search-input"
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    <select
      className="client-master-filter-dropdown"
      value={filterBy}
      onChange={(e) => setFilterBy(e.target.value)}
    >
      <option value="">Filter by</option>
      <option value="client_name">Client Name</option>
      <option value="contact_person">Contact Person</option>
      <option value="gst_in">GST IN</option>
      <option value="city">City</option>
      <option value="state">State</option>
      <option value="country">Country</option>
    </select>
    <button className="client-master-button-search" onClick={handleSearch}>
      Search
    </button>
    <button className="client-master-button reset" onClick={handleReset}>
      Reset
    </button>
  </div>
  <div className="client-master-view-buttons">
    <button
      className={`client-master-view-button ${viewMode === 'active' ? 'active' : ''}`}
      onClick={() => setViewMode('active')}
      style={{ backgroundColor: viewMode === 'active' ? 'green' : 'initial' }}
    >
      View Active
    </button>
    <button
      className={`client-master-view-button ${viewMode === 'inactive' ? 'inactive' : ''}`}
      onClick={() => setViewMode('inactive')}
      style={{ backgroundColor: viewMode === 'inactive' ? 'red' : 'initial' }}
    >
      View Deleted
    </button>
  </div>
  {filteredClients.length > 0 ? (
    <>
      <table className="client-master-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                className="client-master-select-all-checkbox"
                checked={selectedRecords.length === filteredClients.length}
                onChange={handleSelectAll}
              />
            </th>
            <th>ID</th>
            <th>Client Name</th>
            <th>Contact Person</th>
            <th>GST IN</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Created By</th>
            <th>Updated By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients.map((client) => (
            <tr key={client.id}>
              <td>
                <input
                  type="checkbox"
                  className="client-master-record-checkbox"
                  checked={selectedRecords.includes(client.id)}
                  onChange={() => handleCheckboxChange(client.id)}
                />
              </td>
              <td>{client.id}</td>
              <td>{client.client_name}</td>
              <td>{client.contact_person}</td>
              <td>{client.gst_in}</td>
              <td>{client.city}</td>
              <td>{client.state}</td>
              <td>{client.country}</td>
              <td>{formatTimestamp(client.created_at)}</td>
              <td>{formatTimestamp(client.updated_at)}</td>
              <td>{client.created_by}</td>
              <td>{client.updated_by}</td>
              <td>
                {viewMode === 'active' ? (
                  <>
                  
                    <button className="client-master-button edit" onClick={() => handleEdit(client)}>
                      Edit
                    </button>
                    <button
                      className="client-master-button delete"
                      onClick={() => {
                        setShowDeleteDialog(true);
                        setDeleteType('soft');
                        setDeleteId(client.id);
                      }}
                    >
                      Delete
                    </button>
                  </>
                ) : (
                  <button
                    className="client-master-button delete-permanent"
                    onClick={() => {
                      setShowDeleteDialog(true);
                      setDeleteType('permanent');
                      setDeleteId(client.id);
                    }}
                  >
                    Delete Permanently
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRecords.length > 0 && (
        <button className="client-master-button bulk-delete" onClick={handleBulkDelete}>
          Delete Selected
        </button>
      )}
    </>
  ) : (
    <p className="client-master-no-records-message">No clients found</p>
  )}
  {showDeleteDialog && (
    <div className="client-master-dialog">
      <div className="client-master-dialog-content">
        <p className="client-master-dialog-text">
          Are you sure you want to {deleteType === 'permanent' ? 'permanently ' : ''}delete this client?
        </p>
        <button className="client-master-button confirm" onClick={handleDelete}>
          Yes
        </button>
        <button className="client-master-button cancel" onClick={() => setShowDeleteDialog(false)}>
          No
        </button>
      </div>
    </div>
  )}
</div>
  );
};

export default ClientMaster;
