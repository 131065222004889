import React, { useState } from 'react';
import './styles/UnclearedJobsReport.css';

const UnclearedJobsReport = () => {
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [refNumber, setRefNumber] = useState('');
  const [customerName, setCustomerName] = useState('');

  const handleView = () => {
    // Fetch and view uncleared jobs data
    console.log('Viewing data with:', { dateFilter, date, refNumber, customerName });
  };

  const handleDownload = () => {
    // Handle Excel download
    console.log('Downloading Excel with:', { dateFilter, date, refNumber, customerName });
  };

  return (
    <div className="uncleared-jobs-report">
      <h1>Uncleared Jobs Report</h1>
      <div className="uncleared-jobs-report-form">
        <div className="uncleared-jobs-report-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="uncleared-jobs-report-form-group">
          <label>Ref#</label>
          <input
            type="text"
            value={refNumber}
            onChange={(e) => setRefNumber(e.target.value)}
          />
        </div>
        <div className="uncleared-jobs-report-form-group">
          <label>Customer Name</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>
        <div className="uncleared-jobs-report-button-group">
          <button onClick={handleView}>View</button>
          <button onClick={handleDownload}>Download Excel</button>
        </div>
      </div>
    </div>
  );
};

export default UnclearedJobsReport;
