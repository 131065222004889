import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './styles/TransporterMaster.css';
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'transporter-master';

const TransporterMaster = () => {
  const [transporterName, setTransporterName] = useState('');
  const [transporters, setTransporters] = useState([]);
  const [inactiveTransporters, setInactiveTransporters] = useState([]);
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [viewMode, setViewMode] = useState('active');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const { user } = useContext(AuthContext);
  const username = user.username;

  useEffect(() => {
    fetchTransporters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  const fetchTransporters = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${viewMode === 'active' ? '' : '/inactive'}`);
      viewMode === 'active' ? setTransporters(response.data) : setInactiveTransporters(response.data);
    } catch (err) {
      console.error('Error fetching transporters:', err);
      setError('Error fetching transporters');
    }
  };

  const handleSave = async () => {
    if (!transporterName) {
      setError('Transporter Name is required');
      return;
    }

    try {
      if (editId) {
        await axios.put(`${BASE_URL}/${editId}`, { transporterName, updatedBy: username });
        setEditId(null);
        setSuccessMessage('Updated successfully');
      } else {
        await axios.post(BASE_URL, { transporterName, createdBy: username });
        setSuccessMessage('Saved successfully');
      }
      fetchTransporters();
      resetForm();
    } catch (err) {
      console.error('Error saving transporter:', err);
      setError('Error saving transporter');
    }
  };

  const handleEdit = (transporter) => {
    setTransporterName(transporter.transporter_name);
    setEditId(transporter.id);
  };

  const handleDelete = async () => {
    const isPermanent = deleteType === 'permanent';
    try {
      await axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${deleteId}`);
      fetchTransporters();
      setShowDeleteDialog(false);
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting transporter:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting transporter`);
    }
  };

  const handleBulkDelete = async () => {
    const isPermanent = viewMode === 'inactive';
    try {
      await Promise.all(selectedRecords.map(id => axios.delete(`${BASE_URL}/${isPermanent ? 'permanent' : 'soft'}/${id}`)));
      fetchTransporters();
      setSuccessMessage(isPermanent ? 'Permanently deleted successfully' : 'Deleted successfully');
    } catch (err) {
      console.error(`Error ${isPermanent ? 'permanently ' : ''}deleting transporters:`, err);
      setError(`Error ${isPermanent ? 'permanently ' : ''}deleting transporters`);
    }
  };

  const handleSearch = () => {
    fetchTransporters();
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilterBy('');
    resetForm();
    fetchTransporters();
    setSuccessMessage('Reset successfully');
  };

  const resetForm = () => {
    setTransporterName('');
    setEditId(null);
    setError('');
  };

  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((recordId) => recordId !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    const allIds = (viewMode === 'active' ? transporters : inactiveTransporters).map(transporter => transporter.id);
    setSelectedRecords(selectedRecords.length === allIds.length ? [] : allIds);
  };

  const filteredTransporters = (viewMode === 'active' ? transporters : inactiveTransporters).filter((transporter) => {
    if (!searchTerm) return true;
    switch (filterBy) {
      case 'transporter_name':
        return transporter.transporter_name.includes(searchTerm);
      case 'created_by':
        return transporter.created_by.includes(searchTerm);
      case 'updated_by':
        return transporter.updated_by.includes(searchTerm);
      case 'month':
        return new Date(transporter.created_at).getMonth() + 1 === parseInt(searchTerm);
      case 'year':
        return new Date(transporter.created_at).getFullYear() === parseInt(searchTerm);
      default:
        return Object.values(transporter).some(value => String(value).includes(searchTerm));
    }
  });

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className="transporter-master">
  <h1 className="transporter-master-title">Transporter Master</h1>
  <div className="transporter-master-form">
    <label className="transporter-master-label">Transporter Name</label>
    <input
      type="text"
      className="transporter-master-input"
      value={transporterName}
      onChange={(e) => setTransporterName(e.target.value)}
    />
    <div className="transporter-master-button-group">
      <button className="transporter-master-button save" onClick={handleSave}>
        {editId ? 'Update' : 'Save'}
      </button>
      <button className="transporter-master-button reset" onClick={handleReset}>
        Reset
      </button>
    </div>
    {error && <p className="transporter-master-message error">{error}</p>}
    {successMessage && <p className="transporter-master-message success">{successMessage}</p>}
  </div>
  <div className="transporter-master-search-filters">
    <input
      type="text"
      className="transporter-master-search-input"
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    <select
      className="transporter-master-filter-dropdown"
      value={filterBy}
      onChange={(e) => setFilterBy(e.target.value)}
    >
      <option value="">Filter by</option>
      <option value="transporter_name">Transporter Name</option>
      <option value="created_by">Created By</option>
      <option value="updated_by">Updated By</option>
      <option value="month">Month</option>
      <option value="year">Year</option>
    </select>
    <button className="transporter-master-button-search" onClick={handleSearch}>
      Search
    </button>
    <button className="transporter-master-button reset" onClick={handleReset}>
      Reset
    </button>
  </div>
  <div className="transporter-master-view-buttons">
    <button
      className={`transporter-master-view-button ${viewMode === 'active' ? 'active' : ''}`}
      onClick={() => setViewMode('active')}
      style={{ backgroundColor: viewMode === 'active' ? 'green' : 'initial' }}
    >
      View Active
    </button>
    <button
      className={`transporter-master-view-button ${viewMode === 'inactive' ? 'inactive' : ''}`}
      onClick={() => setViewMode('inactive')}
      style={{ backgroundColor: viewMode === 'inactive' ? 'red' : 'initial' }}
    >
      View Deleted
    </button>
  </div>
  {filteredTransporters.length > 0 ? (
    <>
      <table className="transporter-master-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                className="transporter-master-select-all-checkbox"
                checked={selectedRecords.length === filteredTransporters.length}
                onChange={handleSelectAll}
              />
            </th>
            <th>ID</th>
            <th>Transporter Name</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Created By</th>
            <th>Updated By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransporters.map((transporter) => (
            <tr key={transporter.id}>
              <td>
                <input
                  type="checkbox"
                  className="transporter-master-record-checkbox"
                  checked={selectedRecords.includes(transporter.id)}
                  onChange={() => handleCheckboxChange(transporter.id)}
                />
              </td>
              <td>{transporter.id}</td>
              <td>{transporter.transporter_name}</td>
              <td>{formatTimestamp(transporter.created_at)}</td>
              <td>{formatTimestamp(transporter.updated_at)}</td>
              <td>{transporter.created_by}</td>
              <td>{transporter.updated_by}</td>
              <td>
                {viewMode === 'active' ? (
                  <>
                    <button className="transporter-master-button edit" onClick={() => handleEdit(transporter)}>
                      Edit
                    </button>
                    <button
                      className="transporter-master-button delete"
                      onClick={() => {
                        setShowDeleteDialog(true);
                        setDeleteType('soft');
                        setDeleteId(transporter.id);
                      }}
                    >
                      Delete
                    </button>
                  </>
                ) : (
                  <button
                    className="transporter-master-button delete-permanent"
                    onClick={() => {
                      setShowDeleteDialog(true);
                      setDeleteType('permanent');
                      setDeleteId(transporter.id);
                    }}
                  >
                    Delete Permanently
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRecords.length > 0 && (
        <button className="transporter-master-button bulk-delete" onClick={handleBulkDelete}>
          Delete Selected
        </button>
      )}
    </>
  ) : (
    <p className="transporter-master-no-records-message">No transporters found</p>
  )}
  {showDeleteDialog && (
    <div className="transporter-master-dialog">
      <div className="transporter-master-dialog-content">
        <p className="transporter-master-dialog-text">
          Are you sure you want to {deleteType === 'permanent' ? 'permanently ' : ''}delete this transporter?
        </p>
        <button className="transporter-master-button confirm" onClick={handleDelete}>
          Yes
        </button>
        <button className="transporter-master-button cancel" onClick={() => setShowDeleteDialog(false)}>
          No
        </button>
      </div>
    </div>
  )}
</div>

  );
};

export default TransporterMaster;
