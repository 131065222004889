// App.js
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import ManageUsers from "./components/ManageUsers";
import Dashboard from "./components/Dashboard";
import Masters from "./components/Masters";
import TaxMaster from "./components/TaxMaster";
import TransporterMaster from "./components/TransporterMaster";
import UOMMaster from "./components/UOMMaster";
import ClientMaster from "./components/ClientMaster";
import ClientDetails from "./components/ClientDetails";
import RateMaster from "./components/RateMaster";
import RateDetails from "./components/RateDetails";
import ChargesMaster from "./components/ChargesMaster";
import InvoiceHead from "./components/InvoiceHead";
import JobDetails from "./components/JobDetails";
import AllVoucherDetails from "./components/AllVoucherDetails";
import FileDelete from "./components/FileDelete";
import FileUpload from "./components/FileUpload";
import IncompleteJobs from "./components/IncompleteJobs";
import ExportJobRegistration from "./components/ExportJobRegistration";
import JobDescription from "./components/JobDescription";
import JobUpdation from "./components/JobUpdation";
import Accounts from "./components/Accounts";
import Billing from "./components/Billing";
import BillPayments from "./components/BillPayments";
import BillingDetails from "./components/BillingDetails";
import CompanyReport from "./components/CompanyReport";
import DeletedInformation from "./components/DeletedInformation";
import GSTPrintAndApprove from "./components/GSTPrintAndApprove";
import PrivateBilling from "./components/PrivateBilling";
import UnbilledInformation from "./components/UnbilledInformation";
import VoucherEntry from "./components/VoucherEntry";
import VoucherPayment from "./components/VoucherPayment";
import VoucherPrintApprove from "./components/VoucherPrintApprove";
import Reports from "./components/Reports";
import BillOutstandingReport from "./components/BillOutstandingReport";
import BillPaymentDetailsReport from "./components/BillPaymentDetailsReport";
import BondHistoryDetailsReport from "./components/BondHistoryDetailsReport";
import ClearanceRegisterReport from "./components/ClearanceRegisterReport";
import FileUploadDetailsReport from "./components/FileUploadDetailsReport";
import JobDetailsReport from "./components/JobDetailsReport";
import ServiceTaxReport from "./components/ServiceTaxReport";
import UnclearedJobsReport from "./components/UnclearedJobsReport";
import VoucherDetailsReport from "./components/VoucherDetailsReport";
import Search from "./components/Search";
import ViewLog from "./components/ViewLog";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import ImportJobRegistration from "./components/ImportJobRegistration";
import ImportJobUpdation from "./components/ImportJobUpdation";
import "./App.css";

import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  const [username] = useState("");

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header username={username} />
          <Navbar />
          <div className="content">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/dashboard"
                element={<ProtectedRoute component={Dashboard} />}
              />
              <Route path="/masters/manage-users" 
              element={<ProtectedRoute component={ManageUsers} />} />
              <Route
                path="/masters"
                element={<ProtectedRoute component={Masters} />}
              />
              <Route
                path="/masters/tax-master"
                element={<ProtectedRoute component={TaxMaster} />}
              />
              <Route
                path="/masters/transporter-master"
                element={<ProtectedRoute component={TransporterMaster} />}
              />
              <Route
                path="/masters/uom-master"
                element={<ProtectedRoute component={UOMMaster} />}
              />
              <Route
                path="/masters/client-master"
                element={<ProtectedRoute component={ClientMaster} />}
              />
              <Route
                path="/masters/client-details"
                element={<ProtectedRoute component={ClientDetails} />}
              />
              <Route
                path="/masters/charges-master"
                element={<ProtectedRoute component={ChargesMaster} />}
              />
              <Route
                path="/masters/invoice-head"
                element={<ProtectedRoute component={InvoiceHead} />}
              />
              <Route
                path="/masters/rate-master"
                element={<ProtectedRoute component={RateMaster} />}
              />
              <Route
                path="/masters/rate-details"
                element={<ProtectedRoute component={RateDetails} />}
              />
              <Route
                path="/job-details"
                element={<ProtectedRoute component={JobDetails} />}
              />
              <Route
                path="/job-details/export-job-registration"
                element={<ProtectedRoute component={ExportJobRegistration} />}
              />
              <Route
                path="/job-details/import-job-registration"
                element={<ProtectedRoute component={ImportJobRegistration} />}
              />
              <Route
                path="/job-details/import-job-updation"
                element={<ProtectedRoute component={ImportJobUpdation} />}
              />
              <Route
                path="/job-details/all-voucher-details"
                element={<ProtectedRoute component={AllVoucherDetails} />}
              />
              <Route
                path="/job-details/file-delete"
                element={<ProtectedRoute component={FileDelete} />}
              />
              <Route
                path="/job-details/file-upload"
                element={<ProtectedRoute component={FileUpload} />}
              />
              <Route
                path="/job-details/incomplete-jobs"
                element={<ProtectedRoute component={IncompleteJobs} />}
              />
              <Route
                path="/job-details/job-description"
                element={<ProtectedRoute component={JobDescription} />}
              />
              <Route
                path="/job-details/job-updation"
                element={<ProtectedRoute component={JobUpdation} />}
              />
              <Route
                path="/accounts"
                element={<ProtectedRoute component={Accounts} />}
              />
              <Route
                path="/accounts/billing"
                element={<ProtectedRoute component={Billing} />}
              />
              <Route
                path="/accounts/bill-payments"
                element={<ProtectedRoute component={BillPayments} />}
              />
              <Route
                path="/accounts/billing-details"
                element={<ProtectedRoute component={BillingDetails} />}
              />
              <Route
                path="/accounts/company-report"
                element={<ProtectedRoute component={CompanyReport} />}
              />
              <Route
                path="/accounts/deleted-information"
                element={<ProtectedRoute component={DeletedInformation} />}
              />
              <Route
                path="/accounts/gst-print-approve"
                element={<ProtectedRoute component={GSTPrintAndApprove} />}
              />
              <Route
                path="/accounts/private-billing"
                element={<ProtectedRoute component={PrivateBilling} />}
              />
              <Route
                path="/accounts/unbilled-information"
                element={<ProtectedRoute component={UnbilledInformation} />}
              />
              <Route
                path="/accounts/voucher-entry"
                element={<ProtectedRoute component={VoucherEntry} />}
              />
              <Route
                path="/accounts/voucher-payment"
                element={<ProtectedRoute component={VoucherPayment} />}
              />
              <Route
                path="/accounts/voucher-print-approve"
                element={<ProtectedRoute component={VoucherPrintApprove} />}
              />
              <Route
                path="/reports"
                element={<ProtectedRoute component={Reports} />}
              />
              <Route
                path="/reports/bill-outstanding-report"
                element={<ProtectedRoute component={BillOutstandingReport} />}
              />
              <Route
                path="/reports/bill-payment-details-report"
                element={<ProtectedRoute component={BillPaymentDetailsReport} />}
              />
              <Route
                path="/reports/bond-history-details-report"
                element={<ProtectedRoute component={BondHistoryDetailsReport} />}
              />
              <Route
                path="/reports/clearance-register-report"
                element={<ProtectedRoute component={ClearanceRegisterReport} />}
              />
              <Route
                path="/reports/file-upload-details-report"
                element={<ProtectedRoute component={FileUploadDetailsReport} />}
              />
              <Route
                path="/reports/job-details-report"
                element={<ProtectedRoute component={JobDetailsReport} />}
              />
              <Route
                path="/reports/service-tax-report"
                element={<ProtectedRoute component={ServiceTaxReport} />}
              />
              <Route
                path="/reports/uncleared-jobs-report"
                element={<ProtectedRoute component={UnclearedJobsReport} />}
              />
              <Route
                path="/reports/voucher-details-report"
                element={<ProtectedRoute component={VoucherDetailsReport} />}
              />
              <Route
                path="/search"
                element={<ProtectedRoute component={Search} />}
              />
              <Route
                path="/view-log"
                element={<ProtectedRoute component={ViewLog} />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
