import React, { useState } from 'react';
import './styles/PrivateBilling.css';

const PrivateBilling = () => {
  const [bill, setBill] = useState('');
  const [year, setYear] = useState('');
  const [date, setDate] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [descriptions, setDescriptions] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [tax, setTax] = useState('');
  const [grossAmount, setGrossAmount] = useState('');
  const [advance, setAdvance] = useState('');
  const [netAmount, setNetAmount] = useState('');

  const addDescription = () => {
    setDescriptions([...descriptions, '']);
  };

  const handleDescriptionChange = (index, value) => {
    const newDescriptions = [...descriptions];
    newDescriptions[index] = value;
    setDescriptions(newDescriptions);
  };

  const handleCreateBill = () => {
    // Handle bill creation logic here
    console.log('Bill created');
  };

  const handleClose = () => {
    // Handle close logic here
    console.log('Closed');
  };

  return (
    <div className="private-billing">
      <h1>Private Billing</h1>
      <form className="private-billing-form">
        <div className="private-billing-form-group">
          <label>Bill:</label>
          <input
            type="text"
            value={bill}
            onChange={(e) => setBill(e.target.value)}
          />
          <label>Year:</label>
          <select value={year} onChange={(e) => setYear(e.target.value)}>
            <option value="">Select Year</option>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            <option value="2023-2024">2023-2024</option>
          </select>
        </div>
        <div className="private-billing-form-group">
          <label>Date:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="private-billing-form-group">
          <label>Customer Name:</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>
        <div className="private-billing-form-group">
          <button type="button" onClick={addDescription}>
            Add Description
          </button>
        </div>
        {descriptions.map((description, index) => (
          <div key={index} className="private-billing-form-group description">
            <input
              type="text"
              value={description}
              onChange={(e) => handleDescriptionChange(index, e.target.value)}
              placeholder={`Description ${index + 1}`}
            />
          </div>
        ))}
        <div className="private-billing-form-group">
          <label>Total Amount:</label>
          <input
            type="number"
            value={totalAmount}
            onChange={(e) => setTotalAmount(e.target.value)}
          />
        </div>
        <div className="private-billing-form-group">
          <label>Tax:</label>
          <input
            type="number"
            value={tax}
            onChange={(e) => setTax(e.target.value)}
          />
        </div>
        <div className="private-billing-form-group">
          <label>Gross Amount:</label>
          <input
            type="number"
            value={grossAmount}
            onChange={(e) => setGrossAmount(e.target.value)}
          />
        </div>
        <div className="private-billing-form-group">
          <label>Advance:</label>
          <input
            type="number"
            value={advance}
            onChange={(e) => setAdvance(e.target.value)}
          />
        </div>
        <div className="private-billing-form-group">
          <label>Net Amount:</label>
          <input
            type="number"
            value={netAmount}
            onChange={(e) => setNetAmount(e.target.value)}
          />
        </div>
        <div className="private-billing-button-group">
          <button type="button" onClick={handleCreateBill}>Create Bill</button>
          <button type="button" onClick={handleClose}>Close</button>
        </div>
      </form>
    </div>
  );
};

export default PrivateBilling;
