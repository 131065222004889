import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './styles/Navbar.css';
import { AuthContext } from '../AuthContext';

function Navbar() {
  const [openMenu, setOpenMenu] = useState('');
  const {logout} = useContext(AuthContext);

  const handleMouseEnter = (menu) => {
    setOpenMenu(menu);
  };

  const handleMouseLeave = () => {
    setOpenMenu('');
  };

  const toggleMenu = (menu) => {
    setOpenMenu(openMenu === menu ? '' : menu);
  };

  const handleLogout = () => {
    logout();
  };

    return (
    <div className="navbar">
      <nav>
        <div className="nav-item" onMouseEnter={() => handleMouseEnter('masters')} onMouseLeave={handleMouseLeave}>
          <button onClick={() => toggleMenu('masters')}>Masters</button>
          {openMenu === 'masters' && (
            <div className="dropdown">
              <NavLink to="/masters/tax-master">Tax Master</NavLink>
              <NavLink to="/masters/transporter-master">Transporter Master</NavLink>
              <NavLink to="/masters/uom-master">UOM Master</NavLink>
              <NavLink to="/masters/client-master">Client Master</NavLink>
              <NavLink to="/masters/client-details">Client Details</NavLink>
              <NavLink to="/masters/charges-master">Charges Master</NavLink>
              <NavLink to="/masters/invoice-head">Invoice Head</NavLink>
              <NavLink to="/masters/rate-master">Rate Master</NavLink>
              <NavLink to="/masters/rate-details">Rate Details</NavLink>
              <NavLink to="/masters/manage-users">Manage Users</NavLink>
            </div>
          )}
        </div>

        <div className="nav-item" onMouseEnter={() => handleMouseEnter('job-details')} onMouseLeave={handleMouseLeave}>
          <button onClick={() => toggleMenu('job-details')}>Job Details</button>
          {openMenu === 'job-details' && (
            <div className="dropdown">
              <NavLink to="/job-details/import-job-registration">Job Registration</NavLink>
              <NavLink to="/job-details/import-job-updation">Job Updation</NavLink>
              <NavLink to="/job-details/file-upload">File Upload</NavLink>
              <NavLink to="/job-details/file-delete">File Delete</NavLink>
              <NavLink to="/job-details/all-voucher-details">All Voucher Details</NavLink>
              <NavLink to="/job-details/incomplete-jobs">Incomplete Jobs</NavLink>
            </div>
          )}
        </div>

        <div className="nav-item" onMouseEnter={() => handleMouseEnter('accounts')} onMouseLeave={handleMouseLeave}>
          <button onClick={() => toggleMenu('accounts')}>Accounts</button>
          {openMenu === 'accounts' && (
            <div className="dropdown">
              <NavLink to="/accounts/voucher-entry">Voucher Entry</NavLink>
              <NavLink to="/accounts/voucher-print-approve">Voucher Approve and Print</NavLink>
              <NavLink to="/accounts/billing">Billing</NavLink>
              <NavLink to="/accounts/gst-print-approve">GST Format Bill Approve and Print</NavLink>
              <NavLink to="/accounts/new-format-bill">New Format Bill Approve and Print</NavLink>
              <NavLink to="/accounts/private-billing">Private Billing</NavLink>
              <NavLink to="/accounts/private-bill-approve">Private Bill Approve and Print</NavLink>
              <NavLink to="/accounts/bill-payments">Bill Payments</NavLink>
              <NavLink to="/accounts/billing-details">Billing Details</NavLink>
              <NavLink to="/accounts/voucher-payment">Voucher Payment</NavLink>
              <NavLink to="/accounts/company-report">Company Report</NavLink>
              <NavLink to="/accounts/deleted-information">Deleted Information</NavLink>
              <NavLink to="/accounts/unbilled-information">Unbilled Information</NavLink>
            </div>
          )}
        </div>
        <div className="nav-item" onMouseEnter={() => handleMouseEnter('reports')} onMouseLeave={handleMouseLeave}>
          <button onClick={() => toggleMenu('reports')}>Reports</button>
          {openMenu === 'reports' && (
            <div className="dropdown">
              <NavLink to="/reports/bill-outstanding-report">Bill Outstanding Report</NavLink>
              <NavLink to="/reports/bill-payment-details-report">Bill Payment Details</NavLink>
              <NavLink to="/reports/bond-history-details-report">Bond History Details</NavLink>
              <NavLink to="/reports/clearance-register-report">Clearance Register</NavLink>
              <NavLink to="/reports/file-upload-details-report">File Upload Details</NavLink>
              <NavLink to="/reports/inbond-stock-details">Inbond Stock Details</NavLink>
              <NavLink to="/reports/job-details-report">Job Details</NavLink>
              <NavLink to="/reports/service-tax-report">Service Tax Report</NavLink>
              <NavLink to="/reports/uncleared-jobs-report">Uncleared Jobs</NavLink>
              <NavLink to="/reports/voucher-details-report">Voucher Details</NavLink>
            </div>
          )}
        </div>


        <div className="nav-item">
          <NavLink to="/search">Search</NavLink>
        </div>
        <div className="nav-item">
          <NavLink to="/view-log">View Log</NavLink>
        </div>
        <div className="nav-item">
          <button onClick={handleLogout}>Logout</button>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
