import React, { useState } from 'react';
import './styles/ClearanceRegisterReport.css';

const ClearanceRegisterReport = () => {
  const [typeOfService, setTypeOfService] = useState('');
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [reportType, setReportType] = useState('');
  const [year, setYear] = useState('');

  const handleView = () => {
    // Fetch and display clearance register details based on the inputs
    console.log('View report for:', { typeOfService, dateFilter, date, customerName, reportType, year });
  };

  const handleDownload = () => {
    // Download the report
    console.log('Download report for:', { typeOfService, dateFilter, date, customerName, reportType, year });
  };

  return (
    <div className="clearance-register-report">
      <h1>Clearance Register Report</h1>
      <div className="clearance-register-form">
        <div className="clearance-register-form-group">
          <label>Type of Service</label>
          <select
            value={typeOfService}
            onChange={(e) => setTypeOfService(e.target.value)}
          >
            <option value="">Select Service Type</option>
            <option value="service1">Service 1</option>
            <option value="service2">Service 2</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="clearance-register-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="clearance-register-form-group">
          <label>Customer Name</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>
        <div className="clearance-register-form-group">
          <label>Report Type</label>
          <select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <option value="">Select Report Type</option>
            <option value="summary">Summary</option>
            <option value="detailed">Detailed</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="clearance-register-form-group">
          <label>Year</label>
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">Select Year</option>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="clearance-register-button-group">
          <button onClick={handleView}>View</button>
          <button onClick={handleDownload}>Download</button>
        </div>
      </div>
    </div>
  );
};

export default ClearanceRegisterReport;
