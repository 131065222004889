import React, { useState } from 'react';
import './styles/JobDescription.css';

const JobDescription = () => {
  const [formData, setFormData] = useState({
    date: '',
    serviceType: '',
    impExpNo: '',
    jobIdNo: '',
    transMode: '',
    branch: '',
    beType: '',
    importerExporter: '',
    consignorConsignee: '',
    countryOfShipment: '',
    portOfShipment: '',
    motherVesselName: '',
    motherVesselNo: '',
    motherVesselEta: '',
    remainder: false,
    feederVesselName: '',
    feederVesselNo: '',
    feederVesselEta: '',
    forwarder: '',
    shippingLine: '',
    rotationNo: '',
    rotationDate: '',
    lineNo: '',
    mblNo: '',
    mblDate: '',
    hblNo: '',
    hblDate: '',
    cbm: '',
    cfs: '',
    netWt: '',
    netWtUnit: '',
    grossWt: '',
    grossWtUnit: '',
    numPkgs: '',
    pkgUnit: '',
    containerNo: '',
    stc: '',
    stcUnit: '',
    stc2: '',
    stc2Unit: '',
    size20: '',
    size40: '',
    totalContainer: '',
    remarks: ''
  });

  const [refNo, setRefNo] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  const generateRefNo = () => {
    const year = new Date().getFullYear().toString().slice(2);
    const transModeType = formData.transMode[0].toUpperCase();
    const id = '000001'; // Simplified example. You should implement logic to generate unique IDs.
    const refNo = `${year}${transModeType}${id}`;
    setRefNo(refNo);
  };

  const getLabel = (field) => {
    if (formData.serviceType === 'import') {
      switch (field) {
        case 'impExpNo': return 'Import No.';
        case 'importerExporter': return 'Importer Name';
        case 'consignorConsignee': return 'Consigner Name';
        default: return '';
      }
    } else if (formData.serviceType === 'export') {
      switch (field) {
        case 'impExpNo': return 'Export No.';
        case 'importerExporter': return 'Exporter Name';
        case 'consignorConsignee': return 'Consignee Name';
        default: return '';
      }
    }
    return '';
  };

  return (
    <div className="job-description">
      <h1>Job Registration</h1>
      <form className="job-description-form" onSubmit={handleSubmit}>
        <div className="job-description-form-row">
          <div className="job-description-form-group">
            <label>Date:</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </div>
          <div className="job-description-form-group">
            <label>Service Type:</label>
            <select
              name="serviceType"
              value={formData.serviceType}
              onChange={handleChange}
            >
              <option value="">Select Service Type</option>
              <option value="import">Import</option>
              <option value="export">Export</option>
            </select>
          </div>
        </div>

        {formData.serviceType && (
          <>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>{getLabel('impExpNo')}:</label>
                <input
                  type="text"
                  name="impExpNo"
                  value={formData.impExpNo}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Job/ID No.:</label>
                <input
                  type="text"
                  name="jobIdNo"
                  value={formData.jobIdNo}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Date:</label>
                <input
                  type="date"
                  name="rotationDate"
                  value={formData.rotationDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Trans Mode:</label>
                <select
                  name="transMode"
                  value={formData.transMode}
                  onChange={handleChange}
                >
                  <option value="">Select Trans Mode</option>
                  <option value="sea">Sea</option>
                  <option value="air">Air</option>
                  {/* Add more options here */}
                </select>
              </div>
              <div className="job-description-form-group">
                <label>Branch:</label>
                <select
                  name="branch"
                  value={formData.branch}
                  onChange={handleChange}
                >
                  <option value="">Select Branch</option>
                  <option value="chennai">Chennai</option>
                  <option value="bangalore">Bangalore</option>
                  {/* Add more options here */}
                </select>
              </div>
              <div className="job-description-form-group">
                <label>S/B Type:</label>
                <select
                  name="beType"
                  value={formData.beType}
                  onChange={handleChange}
                >
                  <option value="">Select S/B Type</option>
                  <option value="sez">SEZ</option>
                  <option value="home">HOME</option>
                  {/* Add more options here */}
                </select>
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>{getLabel('importerExporter')}:</label>
                <input
                  type="text"
                  name="importerExporter"
                  value={formData.importerExporter}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>{getLabel('consignorConsignee')}:</label>
                <input
                  type="text"
                  name="consignorConsignee"
                  value={formData.consignorConsignee}
                  onChange={handleChange}
                />
                <button type="button" onClick={generateRefNo}>Get REF No.</button>
                {refNo && <input type="text" value={refNo} readOnly />}
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Country of Shipment:</label>
                <input
                  type="text"
                  name="countryOfShipment"
                  value={formData.countryOfShipment}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Port of Shipment:</label>
                <input
                  type="text"
                  name="portOfShipment"
                  value={formData.portOfShipment}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Mother Vessel Name:</label>
                <input
                  type="text"
                  name="motherVesselName"
                  value={formData.motherVesselName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Mother Vessel No.:</label>
                <input
                  type="text"
                  name="motherVesselNo"
                  value={formData.motherVesselNo}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Mother Vessel ETA:</label>
                <input
                  type="date"
                  name="motherVesselEta"
                  value={formData.motherVesselEta}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Feeder Vessel Name:</label>
                <input
                  type="text"
                  name="feederVesselName"
                  value={formData.feederVesselName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Feeder Vessel No.:</label>
                <input
                  type="text"
                  name="feederVesselNo"
                  value={formData.feederVesselNo}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Feeder Vessel ETA:</label>
                <input
                  type="date"
                  name="feederVesselEta"
                  value={formData.feederVesselEta}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Forwarder:</label>
                <input
                  type="text"
                  name="forwarder"
                  value={formData.forwarder}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Shipping Line:</label>
                <input
                  type="text"
                  name="shippingLine"
                  value={formData.shippingLine}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Rotation No.:</label>
                <input
                  type="text"
                  name="rotationNo"
                  value={formData.rotationNo}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Rotation Date:</label>
                <input
                  type="date"
                  name="rotationDate"
                  value={formData.rotationDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Line No.:</label>
                <input
                  type="text"
                  name="lineNo"
                  value={formData.lineNo}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>MBL No.:</label>
                <input
                  type="text"
                  name="mblNo"
                  value={formData.mblNo}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>MBL Date:</label>
                <input
                  type="date"
                  name="mblDate"
                  value={formData.mblDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>HBL No.:</label>
                <input
                  type="text"
                  name="hblNo"
                  value={formData.hblNo}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>HBL Date:</label>
                <input
                  type="date"
                  name="hblDate"
                  value={formData.hblDate}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>CBM:</label>
                <input
                  type="text"
                  name="cbm"
                  value={formData.cbm}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>CFS:</label>
                <input
                  type="text"
                  name="cfs"
                  value={formData.cfs}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Net WT:</label>
                <input
                  type="text"
                  name="netWt"
                  value={formData.netWt}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Net WT Unit:</label>
                <select
                  name="netWtUnit"
                  value={formData.netWtUnit}
                  onChange={handleChange}
                >
                  <option value="">Select Unit</option>
                  <option value="kg">KG</option>
                  <option value="ton">Ton</option>
                  {/* Add more options here */}
                </select>
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Gross WT:</label>
                <input
                  type="text"
                  name="grossWt"
                  value={formData.grossWt}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Gross WT Unit:</label>
                <select
                  name="grossWtUnit"
                  value={formData.grossWtUnit}
                  onChange={handleChange}
                >
                  <option value="">Select Unit</option>
                  <option value="kg">KG</option>
                  <option value="ton">Ton</option>
                  {/* Add more options here */}
                </select>
              </div>
              <div className="job-description-form-group">
                <label>Number of Packages:</label>
                <input
                  type="text"
                  name="numPkgs"
                  value={formData.numPkgs}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Package Unit:</label>
                <select
                  name="pkgUnit"
                  value={formData.pkgUnit}
                  onChange={handleChange}
                >
                  <option value="">Select Unit</option>
                  <option value="box">Box</option>
                  <option value="carton">Carton</option>
                  {/* Add more options here */}
                </select>
              </div>
              <div className="job-description-form-group">
                <label>Container No.:</label>
                <input
                  type="text"
                  name="containerNo"
                  value={formData.containerNo}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>STC:</label>
                <input
                  type="text"
                  name="stc"
                  value={formData.stc}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>STC Unit:</label>
                <select
                  name="stcUnit"
                  value={formData.stcUnit}
                  onChange={handleChange}
                >
                  <option value="">Select Unit</option>
                  <option value="box">Box</option>
                  <option value="carton">Carton</option>
                  {/* Add more options here */}
                </select>
              </div>
              <div className="job-description-form-group">
                <label>STC 2:</label>
                <input
                  type="text"
                  name="stc2"
                  value={formData.stc2}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>STC 2 Unit:</label>
                <select
                  name="stc2Unit"
                  value={formData.stc2Unit}
                  onChange={handleChange}
                >
                  <option value="">Select Unit</option>
                  <option value="box">Box</option>
                  <option value="carton">Carton</option>
                  {/* Add more options here */}
                </select>
              </div>
            </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Size 20:</label>
                <input
                  type="text"
                  name="size20"
                  value={formData.size20}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Size 40:</label>
                <input
                  type="text"
                  name="size40"
                  value={formData.size40}
                  onChange={handleChange}
                />
              </div>
              <div className="job-description-form-group">
                <label>Total Container:</label>
                <input
                  type="text"
                  name="totalContainer"
                  value={formData.totalContainer}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="job-description-form-row">
            <div className="job-description-form-group">
              <label>Invoice Details:</label>
              <a href="#remarks" onClick={() => document.getElementById('remarks').focus()}>
                Click to add Invoice Details
              </a>
            </div>
            <div className="job-description-form-group">
              <label>Freight Details:</label>
              <a href="#remarks" onClick={() => document.getElementById('remarks').focus()}>
                Click to add Freight Details
              </a>
            </div>
          </div>
            <div className="job-description-form-row">
              <div className="job-description-form-group">
                <label>Remarks:</label>
                <textarea
                  id="remarks"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        )}

        <button type="submit">Save</button>
        <button type="reset">Reset</button>
      </form>
    </div>
  );
};

export default JobDescription;
