import React, { useState } from 'react';
import './styles/ManageUsers.css';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BACKEND_URL

const ManageUsers = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(BASE_URL +'/register', { username, password });
      setMessage(response.data.message);
      setUsername('');
      setPassword('');
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div class="manage-users">
    <h1>Manage Users</h1>
    <form onSubmit={handleRegister} class="register-form">
      <div class="manage-users-form-group">
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          class="manage-users-form-input"
        />
      </div>
      <div class="manage-users-form-group">
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          class="manage-users-form-input"
        />
      </div>
      <button type="submit" class="manage-users-submit-button">Register</button>
    </form>
    {message && <p class="message">{message}</p>}
  </div>
  );
};

export default ManageUsers;
