import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import './styles/ClientDetails.css';

const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'client-master';

const ClientDetails = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get(BASE_URL);
      setClients(response.data);
    } catch (err) {
      console.error('Error fetching clients:', err);
    }
  };

  const handleClientSelect = (client) => {
    setSelectedClient(client);
  };

  const handleDownload = () => {
    if (!selectedClient) return;

    const worksheet = XLSX.utils.json_to_sheet([selectedClient]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ClientDetails');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, `${selectedClient.client_name}_details.xlsx`);
  };

  const filteredClients = clients.filter((client) =>
    client.client_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
<div className="client-details-container">
  <h1 className="client-details-title">Client Details</h1>
  
  <div className="client-details-search">
    <input
      type="text"
      className="client-details-search-input"
      placeholder="Search client"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>
  
  <div className="client-details-list">
    {filteredClients.map((client) => (
      <div
        key={client.id}
        className="client-details-item"
        onClick={() => handleClientSelect(client)}
      >
        {client.client_name}
      </div>
    ))}
  </div>
  
  {selectedClient && (
    <div className="client-details-display">
      <h2 className="client-details-subtitle">Details for {selectedClient.client_name}</h2>
      <p><strong>Contact Person:</strong> {selectedClient.contact_person}</p>
      <p><strong>GST IN:</strong> {selectedClient.gst_in}</p>
      <p><strong>LOP NO:</strong> {selectedClient.lop_no}</p>
      <p><strong>Address:</strong> {selectedClient.address}</p>
      <p><strong>City:</strong> {selectedClient.city}</p>
      <p><strong>State:</strong> {selectedClient.state}</p>
      <p><strong>Country:</strong> {selectedClient.country}</p>
      <p><strong>Zip Code:</strong> {selectedClient.zip_code}</p>
      <p><strong>Phone Number:</strong> {selectedClient.phone_number}</p>
      <p><strong>Mobile Number:</strong> {selectedClient.mobile_number}</p>
      <p><strong>Email Address:</strong> {selectedClient.email_address}</p>
      <p><strong>Account Email:</strong> {selectedClient.account_email}</p>
      <p><strong>Additional Address:</strong> {selectedClient.additional_address}</p>
      <p><strong>Created By:</strong> {selectedClient.created_by}</p>
      <p><strong>Updated By:</strong> {selectedClient.updated_by}</p>
      <p><strong>Created At:</strong> {new Date(selectedClient.created_at).toLocaleString()}</p>
      <p><strong>Updated At:</strong> {new Date(selectedClient.updated_at).toLocaleString()}</p>
      <button className="client-details-download" onClick={handleDownload}>Download as Excel</button>
    </div>
  )}
</div>

  );
};

export default ClientDetails;
