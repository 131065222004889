import React, { useState } from 'react';
import './styles/VoucherPayment.css';

const VoucherPayment = () => {
  const [paymentDate, setPaymentDate] = useState('');
  const [dateCondition, setDateCondition] = useState('before');
  const [date, setDate] = useState('');
  const [party, setParty] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [totalAmount] = useState('₹0.00');
  const [year, setYear] = useState('');
  const [category, setCategory] = useState('');
  const [voucherType, setVoucherType] = useState('');
  const [amountToBePaid, setAmountToBePaid] = useState('');
  const [balance] = useState('');

  const handleClick = () => {
    // Handle click logic here
    console.log('Fetching data...');
  };

  const handlePaid = () => {
    // Handle paid logic here
    console.log('Marking as paid...');
  };

  return (
    <div className="voucher-payment">
      <h1>Voucher Payment</h1>
      <form className="voucher-payment-form">
        <div className="voucher-payment-form-group">
          <label>Payment Date:</label>
          <input
            type="date"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
          />
        </div>
        <div className="voucher-payment-form-group">
          <label>Date:</label>
          <select
            value={dateCondition}
            onChange={(e) => setDateCondition(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="voucher-payment-form-group">
          <label>Party:</label>
          <input
            type="text"
            value={party}
            onChange={(e) => setParty(e.target.value)}
          />
        </div>
        <div className="voucher-payment-form-group">
          <label>Reference Number:</label>
          <input
            type="text"
            value={referenceNumber}
            onChange={(e) => setReferenceNumber(e.target.value)}
          />
        </div>
        <div className="voucher-payment-form-group">
          <label>Total Amount:</label>
          <div className="voucher-payment-total-amount">{totalAmount}</div>
        </div>
        <div className="voucher-payment-form-group">
          <label>Year:</label>
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">Select Year</option>
            <option value="2020-2021">2020-2021</option>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
          </select>
        </div>
        <div className="voucher-payment-form-group">
          <label>Category:</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            {/* Add other categories as needed */}
            <option value="Category1">Category1</option>
            <option value="Category2">Category2</option>
          </select>
        </div>
        <div className="voucher-payment-form-group">
          <label>Voucher Type:</label>
          <select
            value={voucherType}
            onChange={(e) => setVoucherType(e.target.value)}
          >
            <option value="">Select Voucher Type</option>
            {/* Add other voucher types as needed */}
            <option value="Type1">Type1</option>
            <option value="Type2">Type2</option>
          </select>
        </div>
        <div className="voucher-payment-button-group">
          <button type="button" onClick={handleClick}>Click</button>
          <button type="button" onClick={handlePaid}>Amount to be Paid</button>
        </div>
        <div className="voucher-payment-form-group">
          <label>Total Amount:</label>
          <input
            type="text"
            value={totalAmount}
            readOnly
          />
        </div>
        <div className="voucher-payment-form-group">
          <label>Amount to be Paid:</label>
          <input
            type="text"
            value={amountToBePaid}
            onChange={(e) => setAmountToBePaid(e.target.value)}
          />
          <button type="button" onClick={handlePaid}>Paid</button>
        </div>
        <div className="voucher-payment-form-group">
          <label>Balance:</label>
          <input
            type="text"
            value={balance}
            readOnly
          />
        </div>
      </form>
    </div>
  );
};

export default VoucherPayment;
