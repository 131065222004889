import React, { useState } from 'react';
import './styles/BillPayments.css';

const BillPayments = () => {
  const [year, setYear] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [billType, setBillType] = useState('');
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [party, setParty] = useState('');
  const [refNumber, setRefNumber] = useState('');

  const handleView = () => {
    // Fetch and view bill payment details
    console.log('Viewing data with:', { year, paymentDate, billType, dateFilter, date, party, refNumber });
  };

  const handleDownload = () => {
    // Handle Excel download
    console.log('Downloading Excel with:', { year, paymentDate, billType, dateFilter, date, party, refNumber });
  };

  const handleSave = () => {
    // Handle save
    console.log('Saving data with:', { year, paymentDate, billType, dateFilter, date, party, refNumber });
  };

  return (
    <div className="bill-payments">
      <h1>Bill Payments</h1>
      <div className="bill-payments-form">
        <div className="bill-payments-form-group">
          <label>Year</label>
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">Select Year</option>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            <option value="2023-2024">2023-2024</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="bill-payments-form-group">
          <label>Payment Date</label>
          <input
            type="date"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
          />
        </div>
        <div className="bill-payments-form-group">
          <label>Bill Type</label>
          <select
            value={billType}
            onChange={(e) => setBillType(e.target.value)}
          >
            <option value="">Select Bill Type</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="bill-payments-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="bill-payments-form-group">
          <label>Party</label>
          <input
            type="text"
            value={party}
            onChange={(e) => setParty(e.target.value)}
          />
        </div>
        <div className="bill-payments-form-group">
          <label>Ref#</label>
          <input
            type="text"
            value={refNumber}
            onChange={(e) => setRefNumber(e.target.value)}
          />
        </div>
        <div className="bill-payments-button-group">
          <button onClick={handleView}>View</button>
          <button onClick={handleDownload}>Download Excel</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default BillPayments;
