import React, { useState, useEffect } from 'react';
import './styles/AllVoucherDetails.css';
import axios from 'axios';

const AllVoucherDetails = () => {
  const [dateField, setDateField] = useState('');
  const [date, setDate] = useState('');
  const [refNo, setRefNo] = useState('');
  const [year, setYear] = useState('');
  const [vouchers, setVouchers] = useState([]);
  const [refNoOptions, setRefNoOptions] = useState([]);
  const [filteredRefNoOptions, setFilteredRefNoOptions] = useState([]); // Added filtered options



  // Fetch ref_no options for datalist
  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + 'import-job/ref-numbers') 
      .then(response => {
        setRefNoOptions(response.data);
        setFilteredRefNoOptions(response.data); // Initialize filtered options
      })
      .catch(error => console.error('Error fetching ref_no options:', error));
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setRefNo(value);

    // Filter ref_no options based on user input, ensuring ref_no is defined
    if (value) {
      const filteredOptions = refNoOptions.filter(option =>
        option?.ref_no && option.ref_no.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredRefNoOptions(filteredOptions);
    } else {
      setFilteredRefNoOptions(refNoOptions); // Reset to full options if input is empty
    }
  };

  const handleShowClick = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + 'all-voucher-details', {
      params: { dateField, date, refNo, year }
    })
    .then(response => setVouchers(response.data))
    .catch(error => console.error('Error fetching voucher details:', error));
  };

  return (
    <div className="all-voucher-container">
    <h2 className="all-voucher-title">All Voucher Details</h2>
    
    <form className="all-voucher-form">
      <div className="all-voucher-form-group">
        <label className="all-voucher-label">Date Field:</label>
        <select 
          className="all-voucher-dropdown" 
          value={dateField} 
          onChange={(e) => setDateField(e.target.value)}
        >
          <option value="">Select</option>
          <option value="before">Before</option>
          <option value="equal">Equal</option>
          <option value="after">After</option>
        </select>
      </div>
      
      <div className="all-voucher-form-group">
        <label className="all-voucher-label">Date:</label>
        <input 
          className="all-voucher-input" 
          type="date" 
          value={date} 
          onChange={(e) => setDate(e.target.value)} 
        />
      </div>
      
      <div className="all-voucher-form-group">
        <label className="all-voucher-label">Reference Number:</label>
        <input 
          className="all-voucher-input" 
          type="text" 
          value={refNo} 
          onChange={handleInputChange} 
          list="refNoOptions" 
          placeholder="Type or select reference number"
        />
        <datalist id="refNoOptions">
          {filteredRefNoOptions.map((option, index) => (
            <option key={index} value={option.ref_no} />
          ))}
        </datalist>
      </div>
      
      <div className="all-voucher-form-group">
        <label className="all-voucher-label">Year:</label>
        <select 
          className="all-voucher-dropdown" 
          value={year} 
          onChange={(e) => setYear(e.target.value)}
        >
          <option value="">Select Year</option>
          <option value="2021-2022">2021-2022</option>
          <option value="2023-2024">2023-2024</option>
          <option value="2024-2025">2024-2025</option>
        </select>
      </div>
      
      <div className="all-voucher-button-group">
        <button 
          type="button" 
          className="all-voucher-button all-voucher-button-show" 
          onClick={handleShowClick}
        >
          Show
        </button>
      </div>
    </form>
  
    <table className="all-voucher-table">
      <thead>
        <tr>
          <th>Voucher Number</th>
          <th>Date</th>
          <th>Reference No</th>
          <th>Expenses</th>
          <th>Expense Type</th>
          <th>Total Amount</th>
          <th>Prepared By</th>
        </tr>
      </thead>
      <tbody>
        {vouchers.map((voucher, index) => (
          <tr key={index}>
            <td>{voucher.voucher_number}</td>
            <td>{new Date(voucher.date).toLocaleDateString()}</td>
            <td>{voucher.ref_no}</td>
            <td>{voucher.expenses}</td>
            <td>{voucher.expense_type}</td>
            <td>{voucher.total_amt}</td>
            <td>{voucher.prepared_by}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  
  );
};

export default AllVoucherDetails;
