import React, { useState } from 'react';
import './styles/UnbilledInformation.css';

const UnbilledInformation = () => {
  const [dateOption, setDateOption] = useState('before');
  const [date, setDate] = useState('');
  const [partyName, setPartyName] = useState('');
  const [year, setYear] = useState('');
  const [ref, setRef] = useState('');
  const [records, setRecords] = useState(0);

  const handleSearch = () => {
    // Handle search logic here
    console.log('Search clicked');
    setRecords(0); // Set records to 0 for now
  };

  return (
    <div className="unbilled-information">
      <h1>Unbilled Information</h1>
      <form className="unbilled-information-form">
        <div className="unbilled-information-form-group">
          <label>Date:</label>
          <select value={dateOption} onChange={(e) => setDateOption(e.target.value)}>
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="unbilled-information-form-group">
          <label>Party Name:</label>
          <input
            type="text"
            value={partyName}
            onChange={(e) => setPartyName(e.target.value)}
          />
        </div>
        <div className="unbilled-information-form-group">
          <label>Year:</label>
          <select value={year} onChange={(e) => setYear(e.target.value)}>
            <option value="">Select Year</option>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            <option value="2023-2024">2023-2024</option>
          </select>
        </div>
        <div className="unbilled-information-form-group">
          <label>Ref#:</label>
          <input
            type="text"
            value={ref}
            onChange={(e) => setRef(e.target.value)}
          />
        </div>
        <div className="unbilled-information-form-group">
          <button type="button" onClick={handleSearch}>Search</button>
        </div>
      </form>
      <div className="unbilled-information-records">
        <p>No. of Records: {records}</p>
      </div>
    </div>
  );
};

export default UnbilledInformation;
