import React, { useState } from 'react';
import './styles/BillingDetails.css';

const BillingDetails = () => {
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [customer, setCustomer] = useState('');
  const [refNumber, setRefNumber] = useState('');
  const [billStatus, setBillStatus] = useState('');

  const handleClick = () => {
    // Handle fetching and showing summary
    console.log('Fetching data with:', { dateFilter, date, customer, refNumber, billStatus });
    // Mocked values for the summary
    document.getElementById('total-bills').innerText = '10';
    document.getElementById('total-bill-amount').innerText = '$5000';
    document.getElementById('total-tax-amount').innerText = '$500';
    document.getElementById('total-advance-amount').innerText = '$1000';
    document.getElementById('total-net-amount').innerText = '$4500';
  };

  const handleDownload = () => {
    // Handle Excel download
    console.log('Downloading Excel with:', { dateFilter, date, customer, refNumber, billStatus });
  };

  return (
    <div className="billing-details">
      <h1>Billing Details</h1>
      <div className="billing-details-form">
        <div className="billing-details-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="billing-details-form-group">
          <label>Customer</label>
          <input
            type="text"
            value={customer}
            onChange={(e) => setCustomer(e.target.value)}
          />
        </div>
        <div className="billing-details-form-group">
          <label>Ref#</label>
          <input
            type="text"
            value={refNumber}
            onChange={(e) => setRefNumber(e.target.value)}
          />
        </div>
        <div className="billing-details-form-group">
          <label>Bill Status</label>
          <select
            value={billStatus}
            onChange={(e) => setBillStatus(e.target.value)}
          >
            <option value="">Select Status</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
            <option value="pending">Pending</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="billing-details-button-group">
          <button onClick={handleClick}>Click</button>
          <button onClick={handleDownload}>Download Excel</button>
        </div>
      </div>
      <div className="billing-summary">
        <p>Total No. of Bills: <span id="total-bills">0</span></p>
        <p>Total Bill Amount: <span id="total-bill-amount">$0</span></p>
        <p>Total Tax Amount: <span id="total-tax-amount">$0</span></p>
        <p>Total Advance Amount: <span id="total-advance-amount">$0</span></p>
        <p>Total Net Amount: <span id="total-net-amount">$0</span></p>
      </div>
    </div>
  );
};

export default BillingDetails;
