import React, { useState } from 'react';
import './styles/ImportJobRegistration.css';
import axios from 'axios'; 


const BASE_URL = process.env.REACT_APP_BACKEND_URL + 'import-job';

const ImportJobRegistration = () => {
    const [formData, setFormData] = useState({
        date: '',
        service_type: 'Imports',
        import_no: '',
        job_id: '',
        job_date: '',
        trans_mode: 'Sea',
        branch: 'Chennai',
        be_type: 'SEZ',
        importer_name: '',
        consignor_name: '',
        ref_no: '',
        country_of_shipment: '',
        port_of_shipment: '',
        mother_vessel_name: '',
        v_no: '',
        eta: '',
        remainder: false,
        feeder_vessel_name: '',
        feeder_v_no: '',
        feeder_eta: '',
        forwarder: '',
        shipping_line: '',
        rotation: '',
        rotation_date: '',
        line_no: '',
        mbl_no: '',
        mbl_date: '',
        hbl_no: '',
        hbl_date: '',
        cbm: 0,
        cfs: '',
        net_wt: 0,
        net_wt_unit: '',
        gross_wt: 0,
        gross_wt_unit: '',
        num_of_pkgs: 0,
        num_of_pkgs_unit: '',
        container_no: '',
        stc: '',
        stc_unit: '',
        stc2: '',
        stc2_unit: '',
        size_20: 0,
        size_40: 0,
        total_container: 0,
        remarks: '',
        terms: '',
        currency: '',
        invoice: '',
        invoice_date: '',
        po: '',
        po_date: '',
        invoice_description: '',
        qty: '',
        unit: '',
        unit_price: '',
        unit_value: '',
        freight: '',
        insurance: '',
        other_charges: '',
        fob_value: ''
    });

    const [lastRecordId, setLastRecordId] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
    const [isFreightPopupOpen, setIsFreightPopupOpen] = useState(false);
    const [errors, setErrors] = useState({});

    const generateRefNo = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'import-job/get-latest-record-number');
            
            if (!response.ok) {
                throw new Error('Failed to fetch the latest record number');
            }
    
            const data = await response.json();
        
            const incrementedRecordNumber = data.latestRecordNumber;
    
            const year = new Date().getFullYear();
            const yearSuffix = year % 100;
            const serviceTypeInitial = formData.service_type[0].toUpperCase();
            const transModeInitial = formData.trans_mode[0].toUpperCase();
            const nameInitial = formData.service_type === 'Imports'
                ? formData.importer_name[0].toUpperCase()
                : formData.consignor_name[0].toUpperCase();
    
            const refNo = `${yearSuffix}${serviceTypeInitial}${transModeInitial}${nameInitial}${incrementedRecordNumber}`;
    
            setFormData(prevData => ({
                ...prevData,
                ref_no: refNo
            }));
    
        } catch (error) {
            console.error('Error generating reference number:', error);
        }
    };

    const handleGetRefNo = () => {
        const newErrors = {};

        // Validate required fields
        if (!formData.date) newErrors.date = 'Date is required';
        if (!formData.service_type) newErrors.service_type = 'Service Type is required';
        if (!formData.import_no) newErrors.import_no = 'Import No. is required';
        if (!formData.job_id) newErrors.job_id = 'Job ID is required';
        if (!formData.job_date) newErrors.job_date = 'Job Date is required';
        if (!formData.trans_mode) newErrors.trans_mode = 'Trans Mode is required';
        if (!formData.branch) newErrors.branch = 'Branch is required';
        if (!formData.be_type) newErrors.be_type = 'B/E Type is required';
        if (formData.service_type === 'Imports' && !formData.importer_name) newErrors.importer_name = 'Importer Name is required';
        if (formData.service_type === 'Exports' && !formData.consignor_name) newErrors.consignor_name = 'Consignor Name is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors({});
            generateRefNo();
        }
    };

    const validateForm = () => {
        if (!formData.date) return "Date is required.";
        if (!formData.mbl_no.match(/^\d+$/)) return "MBL No. must be a number.";
        if (formData.qty && !formData.qty.match(/^\d+(\.\d+)?$/)) return "Quantity must be a valid number.";
        return null;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const getLabel = (label) => {
        if (formData.service_type === 'Exports') {
            switch (label) {
                case 'Import No': return 'Export No';
                case 'B/E Type': return 'S/B Type';
                case 'Importer Name': return 'Exporter Name';
                case 'Consignor Name': return 'Consignee Name';
                default: return label;
            }
        }
        return label;
    };

    const handleSave = async (event) => {
        event.preventDefault();
        const error = validateForm();
        if (error) {
            alert(error);
            setErrorMessage(error);
            return;
        }
        try {
            await axios.post(BASE_URL, formData);
            setSuccessMessage('Job registered successfully');
            setErrorMessage('');
            resetForm();
            setTimeout(() => {
                setSuccessMessage('');
            }, 10000);
        } catch (err) {
            console.error('Error saving job:', err);
            setErrorMessage('Error saving job');
            setSuccessMessage('');
        }
    };

    const resetForm = () => {
        setFormData({
            date: '',
            service_type: 'Imports',
            import_no: '',
            job_id: '',
            job_date: '',
            trans_mode: 'Sea',
            branch: 'Chennai',
            be_type: 'SEZ',
            importer_name: '',
            consignor_name: '',
            ref_no: '',
            country_of_shipment: '',
            port_of_shipment: '',
            mother_vessel_name: '',
            v_no: '',
            eta: '',
            remainder: false,
            feeder_vessel_name: '',
            feeder_v_no: '',
            feeder_eta: '',
            forwarder: '',
            shipping_line: '',
            rotation: '',
            rotation_date: '',
            line_no: '',
            mbl_no: '',
            mbl_date: '',
            hbl_no: '',
            hbl_date: '',
            cbm: 0,
            cfs: '',
            net_wt: 0,
            net_wt_unit: '',
            gross_wt: 0,
            gross_wt_unit: '',
            num_of_pkgs: 0,
            num_of_pkgs_unit: '',
            container_no: '',
            stc: '',
            stc_unit: '',
            stc2: '',
            stc2_unit: '',
            size_20: 0,
            size_40: 0,
            total_container: 0,
            remarks: '',
            terms: '',
            currency: '',
            invoice: '',
            invoice_date: '',
            po: '',
            po_date: '',
            invoice_description: '',
            qty: '',
            unit: '',
            unit_price: '',
            unit_value: '',
            freight: '',
            insurance: '',
            other_charges: '',
            fob_value: ''
        });
        setLastRecordId(lastRecordId + 1);
    };

    return (
        <div className="import-job-registration">
            <form onSubmit={handleSave}>
                <div>
                    <div className="import-job-row-title">Job Registration</div>
                </div>
                <br />
                <div className="import-job-row">
                    <label>Date:{errors.date && <div className="error">{errors.date}</div>} <input type="date" name="date" value={formData.date} onChange={handleChange} required /></label>
                    <label>Service Type: {errors.service_type && <div className="error">{errors.service_type}</div>}
                        <select name="service_type" value={formData.service_type} onChange={handleChange}>
                            <option value="Imports">Imports</option>
                            <option value="Exports">Exports</option>
                        </select>
                    </label>
                    
                    <label>{getLabel('Imp/Exp No.')}: {errors.import_no && <div className="error">{errors.import_no}</div>} <input type="text" name="import_no" value={formData.import_no} onChange={handleChange} /></label>
                    
                    <label>Job ID:{errors.job_id && <div className="error">{errors.job_id}</div>} <input type="text" name="job_id" value={formData.job_id} onChange={handleChange} /></label>
                    
                </div>
                
                <div className="import-job-row">
                    <label>Job Date:{errors.job_date && <div className="error">{errors.job_date}</div>} <input type="date" name="job_date" value={formData.job_date} onChange={handleChange} /></label>
                    
                    <label>Trans Mode: {errors.trans_mode && <div className="error">{errors.trans_mode}</div>}
    <input 
        type="text" 
        name="trans_mode" 
        list="trans_mode_options" 
        value={formData.trans_mode} 
        onChange={handleChange} 
    />
    <datalist id="trans_mode_options">
        <option value="Sea" />
        <option value="Air" />
        <option value="Road" />
        <option value="Courier" />
    </datalist>
</label>

                    <label>Branch: {errors.branch && <div className="error">{errors.branch}</div>} <input type="text" name="branch" value={formData.branch} onChange={handleChange} /></label>
                   
                    <label>{getLabel('B/E Type')}: {errors.be_type && <div className="error">{errors.be_type}</div>} <input type="text" name="be_type" value={formData.be_type} onChange={handleChange} /></label>
                    
                </div>
                
                <div className="import-job-row">
                    <label>{getLabel('Importer Name')}: {errors.importer_name && <div className="error">{errors.importer_name}</div>}<input type="text" name="importer_name" value={formData.importer_name} onChange={handleChange} /></label>
                    
                    <label>{getLabel('Consignor Name')}: {errors.consignor_name && <div className="error">{errors.consignor_name}</div>} <input type="text" name="consignor_name" value={formData.consignor_name} onChange={handleChange} /></label>
                    
                    <label>Ref No.: 
                        <div className="ref-no-group">
                            <input type="text" name="ref_no" value={formData.ref_no} readOnly />
                            <button type="button" onClick={handleGetRefNo}>Get Ref No.</button>
                        </div>
                    </label>
                    
                </div>
                
                <div className="import-job-row">
                <div className="import-job-row-title">Shipping Details</div>
                </div>
                <div className="import-job-row">
                <label>
                Country of Shipment:
                <input
                    type="text"
                    name="country_of_shipment"
                    value={formData.country_of_shipment}
                    onChange={handleChange}
                    list="countries"
                    placeholder="Select or type a country"
                />
            </label>
            <datalist id="countries">
                <option value="JAPAN" />
                <option value="QATAR" />
                <option value="USA" />
                <option value="NETHERLAND" />
                <option value="SINGAPORE" />
                <option value="UNITED KINGDOM" />
                <option value="UNITED STATES" />
                <option value="SWEDEN" />
                <option value="THAILAND" />
                <option value="CHINA" />
                <option value="INDIA" />
                <option value="KENYA" />
                <option value="EGYPT" />
                <option value="KOREA" />
                <option value="FRANCE" />
                <option value="MEPZ-SEZ" />
                <option value="ETHIOPIA" />
                <option value="INDONESIA" />
                <option value="RWANDA" />
                <option value="TAIWAN" />
                <option value="SATHAKUPPAM" />
                <option value="SOUTH AFRICA" />
                <option value="UGANDA" />
                <option value="AUSTRALIA" />
                <option value="SRILANKA" />
                <option value="KENYA" />
            </datalist>
            <label>Port of Shipment: 
    <input 
        type="text" 
        name="port_of_shipment" 
        value={formData.port_of_shipment} 
        onChange={handleChange} 
        list="port_options" 
        placeholder="Select or type a port"
    />
</label>
<datalist id="port_options">
    <option value="YOKOHAMA" />
    <option value="PORT HAMAD" />
    <option value="OAKLAND" />
    <option value="LOS ANGELES" />
    <option value="ROTTERDAM" />
    <option value="SINGAPORE" />
    <option value="LONDON" />
    <option value="DOHA" />
    <option value="DOHA QATAR" />
    <option value="NEW YORK" />
    <option value="BOSTON" />
    <option value="PAT BANGKOK" />
    <option value="OSAKA" />
    <option value="JACKSONVILLE" />
    <option value="XIANGANG" />
    <option value="MOMBASA" />
    <option value="XIAMEN" />
    <option value="LE HAVRE" />
    <option value="NINGBO" />
    <option value="HOUSTON" />
    <option value="NEW YORK" />
</datalist>
                </div>
                <div className="import-job-row">
                    <label>Mother Vessel Name: <input type="text" name="mother_vessel_name" value={formData.mother_vessel_name} onChange={handleChange} /></label>
                    <label>Mother Vessel No: <input type="text" name="v_no" value={formData.v_no} onChange={handleChange} /></label>
                    <label>ETA: <input type="date" name="eta" value={formData.eta} onChange={handleChange} /></label>
                </div>
                
                <div className="import-job-row">
                    <label>Feeder Vessel Name: <input type="text" name="feeder_vessel_name" value={formData.feeder_vessel_name} onChange={handleChange} /></label>
                    <label>Feeder Vessel No: <input type="text" name="feeder_v_no" value={formData.feeder_v_no} onChange={handleChange} /></label>
                    <label>ETA: <input type="date" name="feeder_eta" value={formData.feeder_eta} onChange={handleChange} /></label>
                </div>
                <div className="import-job-row">
                    <label>Forwarder: <input type="text" name="forwarder" value={formData.forwarder} onChange={handleChange} /></label>
                    <label>Shipping Line: <input type="text" name="shipping_line" value={formData.shipping_line} onChange={handleChange} /></label>
                </div>
                
                <div className="import-job-row">
                    <label>Rotation No.: <input type="text" name="rotation" value={formData.rotation} onChange={handleChange} /></label>
                    <label>Rotation Date: <input type="date" name="rotation_date" value={formData.rotation_date} onChange={handleChange} /></label>
                    <label>Line No.: <input type="text" name="line_no" value={formData.line_no} onChange={handleChange} /></label>
                </div>
                
                <div className="import-job-row">
                    <label>MBL No.: <input type="text" name="mbl_no" value={formData.mbl_no} onChange={handleChange} /></label>
                    <label>MBL Date: <input type="date" name="mbl_date" value={formData.mbl_date} onChange={handleChange} /></label>
                    <label>HBL No.: <input type="text" name="hbl_no" value={formData.hbl_no} onChange={handleChange} /></label>
                    <label>HBL Date: <input type="date" name="hbl_date" value={formData.hbl_date} onChange={handleChange} /></label>
                    <label>CBM: <input type="number" name="cbm" value={formData.cbm} onChange={handleChange} /></label>
                    <label>CFS: <input type="text" name="cfs" value={formData.cfs} onChange={handleChange} /></label>
                </div>
                
                <div className="import-job-row">
                    <label>Net Weight: <input type="number" name="net_wt" value={formData.net_wt} onChange={handleChange} /></label>
                    <label>Net Weight Unit:
    <select 
        name="net_wt_unit" 
        value={formData.net_wt_unit} 
        onChange={handleChange}
    >
        <option value="" disabled>Select a unit</option>
        <option value="LTR">LTR</option>
        <option value="PKGS">PKGS</option>
        <option value="PALLET">PALLET</option>
        <option value="BOX">BOX</option>
        <option value="NOS">NOS</option>
        <option value="PKTS">PKTS</option>
        <option value="KGS">KGS</option>
        <option value="PCS">PCS</option>
        <option value="CTN">CTN</option>
        <option value="MTS">MTS</option>
        <option value="MTR">MTR</option>
        <option value="ROLLS">ROLLS</option>
        <option value="BLS">BLS</option>
        <option value="CTNS">CTNS</option>
        <option value="BOXES">BOXES</option>
        <option value="PAIRS">PAIRS</option>
        <option value="BALES">BALES</option>
        <option value="MTRS">MTRS</option>
        <option value="DRUM">DRUM</option>
        <option value="SQ MTR">SQ MTR</option>
        <option value="THS">THS</option>
    </select>
</label>

                    <label>Gross Weight: <input type="number" name="gross_wt" value={formData.gross_wt} onChange={handleChange} /></label>
                    <label>
    Gross Weight Unit:
    <select
        name="gross_wt_unit"
        value={formData.gross_wt_unit}
        onChange={handleChange}
    >
        <option value="" disabled>Select a unit</option>
        <option value="LTR">LTR</option>
        <option value="PKGS">PKGS</option>
        <option value="PALLET">PALLET</option>
        <option value="BOX">BOX</option>
        <option value="NOS">NOS</option>
        <option value="PKTS">PKTS</option>
        <option value="KGS">KGS</option>
        <option value="PCS">PCS</option>
        <option value="CTN">CTN</option>
        <option value="MTS">MTS</option>
        <option value="MTR">MTR</option>
        <option value="ROLLS">ROLLS</option>
        <option value="BLS">BLS</option>
        <option value="CTNS">CTNS</option>
        <option value="BOXES">BOXES</option>
        <option value="PAIRS">PAIRS</option>
        <option value="BALES">BALES</option>
        <option value="MTRS">MTRS</option>
        <option value="DRUM">DRUM</option>
        <option value="SQ MTR">SQ MTR</option>
        <option value="THS">THS</option>
    </select>
</label>
                    <label>Number of Packages: <input type="number" name="num_of_pkgs" value={formData.num_of_pkgs} onChange={handleChange} /></label>
                    <label>
        Unit:
        <select
            name="num_of_pkgs_unit"
            value={formData.num_of_pkgs_unit}
            onChange={handleChange}
        >
            <option value="" disabled>Select a unit</option>
            <option value="LTR">LTR</option>
            <option value="PKGS">PKGS</option>
            <option value="PALLET">PALLET</option>
            <option value="BOX">BOX</option>
            <option value="NOS">NOS</option>
            <option value="PKTS">PKTS</option>
            <option value="KGS">KGS</option>
            <option value="PCS">PCS</option>
            <option value="CTN">CTN</option>
            <option value="MTS">MTS</option>
            <option value="MTR">MTR</option>
            <option value="ROLLS">ROLLS</option>
            <option value="BLS">BLS</option>
            <option value="CTNS">CTNS</option>
            <option value="BOXES">BOXES</option>
            <option value="PAIRS">PAIRS</option>
            <option value="BALES">BALES</option>
            <option value="MTRS">MTRS</option>
            <option value="DRUM">DRUM</option>
            <option value="SQ MTR">SQ MTR</option>
            <option value="THS">THS</option>
        </select>
    </label>
    <label>Container No.: <input type="text" name="container_no" value={formData.container_no} onChange={handleChange} /></label>
                </div>
                
                <div className="import-job-row">
                    <label>STC: <input type="text" name="stc" value={formData.stc} onChange={handleChange} /></label>
                    <label>
    STC Unit:
    <select
        name="stc_unit"
        value={formData.stc_unit}
        onChange={handleChange}
    >
        <option value="" disabled>Select a unit</option>
        <option value="LTR">LTR</option>
        <option value="PKGS">PKGS</option>
        <option value="PALLET">PALLET</option>
        <option value="BOX">BOX</option>
        <option value="NOS">NOS</option>
        <option value="PKTS">PKTS</option>
        <option value="KGS">KGS</option>
        <option value="PCS">PCS</option>
        <option value="CTN">CTN</option>
        <option value="MTS">MTS</option>
        <option value="MTR">MTR</option>
        <option value="ROLLS">ROLLS</option>
        <option value="BLS">BLS</option>
        <option value="CTNS">CTNS</option>
        <option value="BOXES">BOXES</option>
        <option value="PAIRS">PAIRS</option>
        <option value="BALES">BALES</option>
        <option value="MTRS">MTRS</option>
        <option value="DRUM">DRUM</option>
        <option value="SQ MTR">SQ MTR</option>
        <option value="THS">THS</option>
    </select>
</label>
                    <label>STC2: <input type="text" name="stc2" value={formData.stc2} onChange={handleChange} /></label>
                    <label>
    STC2 Unit:
    <select
        name="stc2_unit"
        value={formData.stc2_unit}
        onChange={handleChange}
    >
        <option value="" disabled>Select a unit</option>
        <option value="LTR">LTR</option>
        <option value="PKGS">PKGS</option>
        <option value="PALLET">PALLET</option>
        <option value="BOX">BOX</option>
        <option value="NOS">NOS</option>
        <option value="PKTS">PKTS</option>
        <option value="KGS">KGS</option>
        <option value="PCS">PCS</option>
        <option value="CTN">CTN</option>
        <option value="MTS">MTS</option>
        <option value="MTR">MTR</option>
        <option value="ROLLS">ROLLS</option>
        <option value="BLS">BLS</option>
        <option value="CTNS">CTNS</option>
        <option value="BOXES">BOXES</option>
        <option value="PAIRS">PAIRS</option>
        <option value="BALES">BALES</option>
        <option value="MTRS">MTRS</option>
        <option value="DRUM">DRUM</option>
        <option value="SQ MTR">SQ MTR</option>
        <option value="THS">THS</option>
    </select>
</label>
                </div>
                
                <div className="import-job-row">
                    <label>Size 20': <input type="number" name="size_20" value={formData.size_20} onChange={handleChange} /></label>
                    <label>Size 40': <input type="number" name="size_40" value={formData.size_40} onChange={handleChange} /></label>
                </div>
    
                <div className="import-job-row">
                    <label>Total Containers: <input type="number" name="total_container" value={formData.total_container} onChange={handleChange} /></label>
                    <label>Remarks: <input type="text" name="remarks" value={formData.remarks} onChange={handleChange} /></label>
                    <label>Remainder: 
                        <input type="checkbox" name="remainder" checked={formData.remainder} onChange={handleChange} />
                    </label>
                </div>

                <div className="import-job-row">
                    <label>Invoice Details: <button type="button" className="import-job-details-button" onClick={() => setIsInvoicePopupOpen(true)}>Invoice Details</button></label>
                

                {isInvoicePopupOpen && (
                                    <div className="upd-popup">
                                        <div className="upd-popup-inner">
                                            <div className="upd-popup-header">
                                                <h4>Invoice Details</h4>

                                            </div>
                                            <div className="upd-popup-content">
                                                <div className="form-row">
                                                    <div className="form-group">
                                                    <label>Terms: 
    <input 
        type="text" 
        name="terms" 
        list="terms_options" 
        value={formData.terms} 
        onChange={handleChange} 
    />
    <datalist id="terms_options">
        <option value="CIF" />
        <option value="Transfer" />
        <option value="CII" />
    </datalist>
</label>
                                                    </div>
                                                    <div className="form-group">
                                                    <label>Currency: 
    <input 
        type="text" 
        name="currency" 
        list="currency_options" 
        value={formData.currency} 
        onChange={handleChange} 
    />
    <datalist id="currency_options">
        <option value="US DOLLAR" />
        <option value="INR" />
        <option value="AUSTRALIAN DOLLAR" />
        <option value="DANISH KRONER" />
        <option value="EURO" />
        <option value="HONG KONG DOLLAR" />
        <option value="JAPANESE YEN(100)" />
        <option value="NORWEGINAN KRONER" />
        <option value="POUND STERLING" />
        <option value="SWEDISH KRONER" />
        <option value="SWISS FRANC" />
        <option value="SINGAPORE DOLLAR" />
    </datalist>
</label>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group">
                                                        <label>Invoice:</label>
                                                        <input type="text" name="invoice" value={formData.invoice} onChange={handleChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Invoice Date:</label>
                                                        <input type="date" name="invoice_date" value={formData.invoice_date} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group">
                                                        <label>PO:</label>
                                                        <input type="text" name="po" value={formData.po} onChange={handleChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
    PO Date:</label>
            <input type="date" name="po_date" value={formData.po_date} onChange={handleChange} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Invoice Description:</label>
            <textarea name="invoice_description" value={formData.invoice_description} onChange={handleChange}></textarea>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Quantity (Qty):</label>
            <input type="number" step="0.01" name="qty" value={formData.qty} onChange={handleChange} />
          </div>
          <div className="form-group">
          <label>Unit: 
    <input 
        type="text" 
        name="unit" 
        list="unit_options" 
        value={formData.unit} 
        onChange={handleChange} 
    />
    <datalist id="unit_options">
        <option value="LTR" />
        <option value="PKGS" />
        <option value="PALLET" />
        <option value="BOX" />
        <option value="NOS" />
        <option value="PKTS" />
        <option value="KGS" />
        <option value="PCS" />
        <option value="CTN" />
        <option value="MTS" />
        <option value="MTR" />
        <option value="ROLLS" />
        <option value="BLS" />
        <option value="CTNS" />
        <option value="BOXES" />
        <option value="PAIRS" />
        <option value="BALES" />
        <option value="MTRS" />
        <option value="DRUM" />
        <option value="SQ MTR" />
        <option value="THS" />
    </datalist>
</label>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Unit Price:</label>
            <input type="number" step="0.01" name="unit_price" value={formData.unit_price} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Unit Value:</label>
            <input type="text" name="unit_value" value={formData.unit_value} onChange={handleChange} />
          </div>
        </div>

        <div className="form-actions">
        <button type="button" onClick={() => setIsInvoicePopupOpen(false)}>Save</button>
          <button type="button" onClick={() => setIsInvoicePopupOpen(false)}>Close</button>
        </div>
      </div>
    </div>
  </div>
)}

                    <label>Freight Details: <button type="button" className="import-job-details-button" onClick={() => setIsFreightPopupOpen(true)}>Freight Details</button></label>
                </div>

                {isFreightPopupOpen && (
  <div className="popup">
    <div className="popup-inner">
      <div className="form-header">
        <h4>Freight Details</h4>

        <div className="form-row">
          <div className="form-group">
            <label>Freight:</label>
            <input type="number" step="0.01" name="freight" value={formData.freight} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Insurance:</label>
            <input type="number" step="0.01" name="insurance" value={formData.insurance} onChange={handleChange} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Other Charges:</label>
            <input type="number" step="0.01" name="other_charges" value={formData.other_charges} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>FOB Value:</label>
            <input type="number" step="0.01" name="fob_value" value={formData.fob_value} onChange={handleChange} />
          </div>
        </div>

        <div className="form-actions">
        <button type="button" onClick={() => setIsFreightPopupOpen(false)}>Save</button>
          <button type="button" onClick={() => setIsFreightPopupOpen(false)}>Close</button>
        </div>
      </div>
    </div>
  </div>
)}
                
                
                <div className="import-job-row">
                    {successMessage && <p className="import-job-success-message">{successMessage}</p>}
                    {errorMessage && <p className="import-job-error-message">{errorMessage}</p>}
                </div>
    
                <div className="import-job-row">
                    <button type="submit" className="import-job-save-button">Save</button>
                    <button type="button" className="import-job-reset-button" onClick={resetForm}>Reset</button>
                </div>
            </form>
        </div>
    );
};

export default ImportJobRegistration;

