import React, { useState } from 'react';
import './styles/FileUploadDetailsReport.css';

const FileUploadDetailsReport = () => {
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [refNumber, setRefNumber] = useState('');

  const handleSearch = () => {
    // Perform search based on the inputs
    console.log('Search for:', { dateFilter, date, refNumber });
  };

  return (
    <div className="file-upload-details">
      <h1>File Upload Details</h1>
      <div className="file-upload-form">
        <div className="file-upload-form-group">
          <label>Date</label>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="file-upload-form-group">
          <label>Ref Number</label>
          <input
            type="text"
            value={refNumber}
            onChange={(e) => setRefNumber(e.target.value)}
          />
        </div>
        <div className="file-upload-button-group">
          <button onClick={handleSearch}>Search</button>
        </div>
      </div>
    </div>
  );
};

export default FileUploadDetailsReport;
